import { Utils } from 'community/services/Utils';

class EnterpriseUtils extends Utils {

}

export default EnterpriseUtils;
export * from 'community/services/Utils';
export {
  EnterpriseUtils as Utils,
};
