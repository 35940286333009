import keyMirror = require('keymirror'); // so sad, so true. no ways to import without errors except for Node require
import * as URI from 'urijs';
import {
  modal as communityModals,
  forms as communityForms,
  adminPages as communityAdminPages,
  apiPaths as communityApiPaths,
  paths as communityPaths,
} from 'community/modules/Admin/const';

const docTypes = {
  DATA_SOURCES: 'data-source',
  USERS: 'user',
};

const attributeTypes = {
  STRING: 'Text',
  ENUM: 'List of Values',
  ENUM_MULTI: 'Multiple values',
  INTEGER: 'Number',
};

const enterpriseForms = {
  ...communityForms,
  ...keyMirror({
    addAttribute: null,
    editAttributeValues: null,
    editAttributeName: null,
    addTenant: null,
    addDSinTenant: null,
    addUserInTenant: null,
    addAtlasInTenant: null,
    addUsersToTenants: null,
  })
};

const enterpriseModal = {
  ...communityModals,
  ...keyMirror({
    addAttribute: null,
    editAttributeValues: null,
    editAttributeName: null,
    addTenant: null,
    addDSinTenant: null,
    addUserInTenant: null,
    addAtlasInTenant: null,
    addUsersToTenants: null,
  })
};

const enterpriseApiPaths = {
  ...communityApiPaths,
  attributes: ({ docType, attrId }) => `/api/v1/metadata/${docType}/attributes${attrId ? `/${attrId}` : '' }`,
  moveAttribute: (docType, attrId) => `/api/v1/metadata/${docType}/attributes/${attrId}/move`,
  updateOptions: () => '/api/v1/metadata/attributes/options/bulk',
  addOption: ({ attributeId }) => `/api/v1/metadata/attributes/${attributeId}/options`,
  removeOption: ({ attrOptionId }) => `/api/v1/metadata/attributes/options/${attrOptionId}`,
  reindexSolr: () => '/api/v1/admin/data-sources/reindex-solr',
  tenants: ({ tenantId = null } = {}) => `/api/v1/tenants${tenantId ? `/${tenantId}` : '' }`,
  tenantDataSources: ({ tenantId = null, dataSourceId = null } = {}) => `/api/v1/tenants/${tenantId}/data-sources${dataSourceId ? `/${dataSourceId}` : '' }`,
  tenantDataSourcesOptions: ({ tenantId = null } = {}) => `/api/v1/tenants/${tenantId}/data-sources/suggest`,
  tenantAtlases: ({ tenantId = null, atlasId = null } = {}) => `/api/v1/tenants/${tenantId}/atlases${atlasId ? `/${atlasId}` : '' }`,
  tenantAtlasesOptions: ({ tenantId = null } = {}) => `/api/v1/tenants/${tenantId}/atlases/suggest`,
  tenantUsers: ({ tenantId = null, userId = null } = {}) => `/api/v1/tenants/${tenantId}/users${userId ? `/${userId}` : '' }`,
  tenantUsersOptions: ({ tenantId = null } = {}) => `/api/v1/tenants/${tenantId}/users/suggest`,
  portalUsersAddToTenants: () => '/api/v1/tenants/users',
};

const enterprisePaths = {
  ...communityPaths,
  metadataAttributes: source => `/admin-settings/metadata-attributes/${source}`,
  tenants: ({ tenantId = null } = {}) => `/admin-settings/tenants${tenantId ? `/${tenantId}` : '' }`,
};

let enterpriseAdminPages = [ ...communityAdminPages ];
if (__APP_TYPE_CENTRAL__) {
  enterpriseAdminPages = enterpriseAdminPages.concat([
    {
      label: 'Data Catalog Metadata',
      value: enterprisePaths.metadataAttributes(docTypes.DATA_SOURCES),
    },
    {
      label: 'Tenants',
      value: enterprisePaths.tenants(),
    },
  ]);
}

export * from 'community/modules/Admin/const';
export {
  // New
  attributeTypes,
  docTypes,
  // Override
  enterpriseAdminPages as adminPages,
  enterpriseForms as forms,
  enterpriseModal as modal,
  enterpriseApiPaths as apiPaths,
  enterprisePaths as paths,
};
