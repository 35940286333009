import Duck from 'community/services/Duck';
import { apiPaths } from 'modules/Admin/const';

const actionCoreName = 'AD_TENANTS_ATLAS';

const attributesDuck = new Duck({
  name: actionCoreName,
  urlBuilder: apiPaths.tenantAtlases,
});

const actions = attributesDuck.actions;
const reducer = attributesDuck.reducer;

export default {
  actions,
  reducer,
};