import * as React from 'react';
import BEMHelper from 'services/BemHelper';
import {
  Checkbox,
  RadioButton,
  Datepicker,
  Select,
} from 'arachne-ui-components';

import { Field } from 'redux-form';
import {
  repeatTypes,
} from 'modules/AnalysisExecution/const';
import * as moment from 'moment';
import MaskedInput from 'react-text-mask';

import './style.scss';

function Input({ options, input }) {
  const classes = BEMHelper('scheduler-input');

  return (
    <input
      {...classes({ modifiers: { narrow: options.isNarrow === true } })}
      placeholder={options.placeholder}
      value={input.value}
      onChange={input.onChange}
      disabled={!options.enabled}
    />
  );
}

function Time({ options, input }) {
  const classes = BEMHelper('scheduler-input');

  return (
    <MaskedInput
      {...classes()}
      mask={[/\d/, /\d/, ':', /\d/, /\d/, ' ', /(a|p)/, 'm']}
      placeholder={options.placeholder}
      value={input.value}
      onChange={input.onChange}
      disabled={!options.enabled}
    />
  );
}

function RepeatType({ options, input }) {
  const classes = BEMHelper('repeat-type');

  return (
    <Select
      {...classes()}
      options={repeatTypes}
      value={input.value}
      onChange={input.onChange}
      mods={['bordered']}
      disabled={!options.enabled}
    />
  );
}

function Date({ options, input }) {
  const classes = BEMHelper('scheduler-date');

  return (
    <div {...classes()}>
      <Datepicker
        {...classes({ element: 'input', modifiers: { disabled: !options.enabled } })}
        selected={moment(input.value, 'MM-DD-YYYY').format('YYYY-MM-DD')}
        onChange={input.onChange}
      />
    </div>
  );
}

function RepeatDay({ options, input }) {
  const classes = BEMHelper('repeat-day');

  return (
    <Checkbox
      {...classes({ modifiers: { checked: input.value === true, disabled: !options.enabled } })}
      isChecked={input.value}
      onChange={input.onChange}
      label={options.name}
    />
  );
}

function Radio({ options, input }) {
  const classes = BEMHelper('repeat-radio');

  return (
    <RadioButton
      {...classes()}
      isChecked={input.value}
      onChange={options.onChange}
      isDisabled={!options.enabled}
    />
  );
}

function Schedule(props) {
  const classes = BEMHelper('analysis-schedule');
  const {
    onEndConditionChange,
    disabled,
    shouldPickDays,
    timeZone,
    isOnce = false,
  } = props;
  const paramClass = classes({ element: 'param', modifiers: { disabled } });
  const weekDays = [
    {
      label: 'su',
      value: 'SUNDAY',
    },
    {
      label: 'm',
      value: 'MONDAY',
    },
    {
      label: 't',
      value: 'TUESDAY',
    },
    {
      label: 'w',
      value: 'WEDNESDAY',
    },
    {
      label: 'th',
      value: 'THURSDAY',
    },
    {
      label: 'f',
      value: 'FRIDAY',
    },
    {
      label: 's',
      value: 'SATURDAY',
    },
  ];

  return (
    <ul {...classes()}>
      <li {...paramClass}>
        <div {...classes('param-title')}>Start on:</div>
        <div {...classes('param-value')}>
          <Field
            component={Time}
            name='repeatTime'
            options={{ enabled: !disabled }}
          />
          {timeZone &&        
            <span {...classes('timezone')}>
              {timeZone}
            </span>
          }
          <Field
            component={Date}
            name='repeatDate'
            options={{ enabled: !disabled }}
          />
        </div>
      </li>
      <li {...paramClass}>
        <div {...classes('param-title')}>Execute:</div>
        <div {...classes('param-value')}>
          <Field
            component={RepeatType}
            name='repeatType'
            options={{ enabled: !disabled }}
          />
        </div>
      </li>
      {shouldPickDays &&
        <li {...paramClass}>
          <div {...classes('param-title')}>Repeat on:</div>
          <div {...classes('param-value')}>
            {weekDays.map((day, index) =>
              <Field
                key={index}
                component={RepeatDay}
                name={`repeatDay[${day.value}]`}
                options={{ enabled: !disabled, name: `${day.label}` }}
              />
            )}
          </div>
        </li>
      }
      {!isOnce && [
        <li {...paramClass}>
          <div {...classes('param-title')}>Ends:</div>
          <div {...classes('param-value')}>
            Never
            <Field
              component={Radio}
              name='repeatEnds[never]'
              options={{
                enabled: !disabled,
                onChange: () => onEndConditionChange('never')
              }}
            />
          </div>
        </li>,
        <li {...paramClass}>
          <div {...classes('param-title')}></div>
          <div {...classes('param-value')}>
            After <Field
              component={Input}
              name='endCount'
              options={{
                enabled: !disabled,
                isNarrow: true,
              }}
            /> exectutions
            <Field
              component={Radio}
              name='repeatEnds[count]'
              options={{
                enabled: !disabled,
                onChange: () => onEndConditionChange('count'),
              }}
            />
          </div>
        </li>,
        <li {...paramClass}>
          <div {...classes('param-title')}></div>
          <div {...classes('param-value')}>
            On 
            <Field
              component={Time}
              name='endTime'
              options={{ enabled: !disabled }}
            />          
            {timeZone &&        
              <span {...classes('timezone')}>
                {timeZone}
              </span>
            }
            <Field
              component={Date}
              name='endDate'
              options={{ enabled: !disabled, placeholder: moment().format('hh:mm') }}
            />
            <Field
              component={Radio}
              name='repeatEnds[date]'
              options={{
                enabled: !disabled,
                onChange: () => onEndConditionChange('date')
              }}
            />
          </div>
        </li>
      ]}
    </ul>
  );
}

export default Schedule;
