import Duck from 'services/Duck';
import { apiPaths } from 'modules/DataCatalog/const';

const actionCoreName = 'DC_REPORTS_ACCESS_PARAMS';

const duck = new Duck({
  name: actionCoreName,
  urlBuilder: apiPaths.reportsPermissions,
});

const actions = duck.actions;
const reducer = duck.reducer;

export default {
  actions,
  reducer,
};
