import * as React from 'react';
import BEMHelper from 'services/BemHelper';

function Privacy(props) {
  const { isPrivate } = props;

  return (<span>{isPrivate ? 'Private' : 'Public'}</span>);
}

export function PrivacyText(props) {
  const { isPrivate } = props;

  return isPrivate ? 'Private' : 'Public';
}

export default Privacy;  
