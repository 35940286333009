/*
 *
 * Copyright 2018 Odysseus Data Services, inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Company: Odysseus Data Services, Inc.
 * Product Owner/Architecture: Gregory Klebanov
 * Authors: Pavel Grafkin, Alexander Saltykov, Vitaly Koulakov, Anton Gackovka, Alexandr Ryabokon, Mikhail Mironov
 * Created: December 26, 2017
 *
 */

import Duck from 'services/Duck';
import { LinkBuilder } from 'modules/AnalysisExecution/ducks/linkBuilder';

export default class DuckBuilder {
  constructor() {
    this.coreName = 'AE_ANALYSIS_SUBMISSION_FILE';
    this.LinkBuilder = LinkBuilder;
  }

  clear() {
    return dispatch => dispatch({
      type: `${this.coreName}_FIND_FULFILLED`,
      payload: null,
    });
  }

  build() {
    const urlBuilder = new (this.LinkBuilder)();
    const duck = new Duck({
      name: this.coreName,
      urlBuilder: urlBuilder.build,
    });

    return {
      actions: {
        ...duck.actions,
        clear: this.clear.bind(this),
      },
      reducer: duck.reducer,
    };
  }
}
