/*
 *
 * Copyright 2018 Odysseus Data Services, inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Company: Odysseus Data Services, Inc.
 * Product Owner/Architecture: Gregory Klebanov
 * Authors: Pavel Grafkin, Alexander Saltykov, Vitaly Koulakov, Anton Gackovka, Alexandr Ryabokon, Mikhail Mironov
 * Created: December 14, 2016
 *
 */

import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import { ModalUtils } from 'arachne-ui-components';
import modulesReducer from './modules';
import routingHistory from './routingHistory';
import { reducer as reduxAsyncConnect } from 'redux-async-connect';

function makeRootReducer(moduleReducers) {
  return combineReducers({
    routing: routerReducer,
    routingHistory,
    form: formReducer,
    modal: ModalUtils.reducer,
    modules: modulesReducer,
    reduxAsyncConnect,
    ...moduleReducers,
  });
}

export default makeRootReducer;
