import { createSelector } from 'reselect';
import { get } from 'services/Utils';
import ISelectorsBuilder from 'interfaces/ISelectorsBuilder';
import * as moment from 'moment';

export default class SelectorsBuilder implements ISelectorsBuilder {
  getRawNotifications(state) {
    return get(state, 'portal.notifications.list.queryResult.result', []) || [];
  }

  getNotifications(rawList) {
    return rawList.map((item) => {
      const invitation = {
        ...item.activity,
        id: item.id,
        shown: item.shown,
        date: moment(item.activity.date).fromNow(),
        sourceDate: item.activity.date,
      };
      const entities = {};
      item.activity.affectedEntities.forEach((entity) => {
        entities[entity.type] = entity.value;
      });
      invitation.affectedEntities = entities;

      return invitation;
    });
  }

  buildSelectorForNotifications() {
    return createSelector(
      [this.getRawNotifications],
      this.getNotifications
    );
  }

  build() {
    return {
    getNotifications: this.buildSelectorForNotifications(),
    }
  }
}
