import { get } from 'services/Utils';
import { attributeNames } from 'const/dataSource';

import CommunityBuilder from 'community/modules/DataCatalog/components/DataSource/ViewEdit/Edit/AttributesList/selectors';
import { executionPolicy, immutableAttributes, executionPolicyField } from 'const/dataSource';

class SelectorsBuilder extends CommunityBuilder {

  getAttrList(state) {
    const metadataAttrList = get(state, 'dataCatalog.dataSourceMetadataAttrs.queryResult.result', [], 'Array');
    const dataNode = this.getDataNode(state);
    const attrList = this.getEnterpriseAttrList(super.getAttrList(), metadataAttrList);
    if (!dataNode.isVirtual && this.getExecutionPolicy(state) !== executionPolicy.MANUAL) {
        const ep = attrList.find(attr => attr.name === executionPolicyField);
        if (ep) {
          ep.options = ep.options.filter(op => op.value !== executionPolicy.MANUAL);
        }
    }
    return attrList;
  }

  getDataNode(state) {
    return get(state, 'dataCatalog.dataSource.data.result.dataNode', {});
  }

  getExecutionPolicy(state) {
    return get(state, 'dataCatalog.dataSource.data.result.executionPolicy');
  }

  getEnterpriseAttrList(staticAttrList, metadataAttrList) {
    staticAttrList.forEach(a => (a.name === attributeNames.modelType ? a.disabled = true : null));
    return [
      ...staticAttrList
        .sort((a, b) => a.order > b.order),
      ...metadataAttrList
        .map(el => ({
          ...el,
          label: el.name,
          name: `metadata[${el.id}]`,
          options: Object.keys(el.options).map(key => ({ label: el.options[key], value: parseInt(key) })),
          isRequired: el.required,
        })),
    ];
  }

  getAttributes(attrList, values) {
    let extendedImmutableAttributes: Array<String> = [...immutableAttributes];
    if (values.executionPolicy === executionPolicy.MANUAL) {
      extendedImmutableAttributes = [
        attributeNames.name,
        attributeNames.executionPolicy,
      ];
    }
    return this.checkImmutability(attrList, extendedImmutableAttributes);
  }

}

export default SelectorsBuilder;