import keyMirror = require('keymirror');

const activityTypes = keyMirror({
  CREATING: null,
  ADDING_CONTRIBUTOR: null,
  CHANGING_CONTRIBUTOR_ROLE: null,
  ADDING_DATA_SOURCE: null,
  CREATING_VIRTUAL_DATA_SOURCE: null,
  ADDING_DATA_SOURCE_OWNER: null,
  ADDING_OWNER_TO_VIRTUAL_DATA_SOURCE: null,
  CREATING_ANALYSIS: null,
  DELETING_DATA_SOURCE: null,
  DELETING_FILE: null,
  DELETING_PARTICIPANT: null,
  INVITATION_ACCEPTED: null,
  INVITATION_DECLINED: null,
  UPDATING_END_DATE: null,
  UPDATING_OBJECTIVE: null,
  UPDATING_PRIVACY: null,
  UPDATING_START_DATE: null,
  UPDATING_STATUS: null,
  UPDATING_TYPE: null,
  UPLOADING_FILE: null,

  DELETING_ANALYSIS: null,
  UPDATING_ANALYSIS: null,
  UPDATING_ANALYSIS_TITLE: null,
  UPDATING_ANALYSIS_DESCRIPTION: null,
  UPDATING_ANALYSIS_TYPE: null,
  LOCKING_ANALYSIS: null,
  SENDING_UNLOCK_ANALYSIS_REQUEST: null,
  PROCESSING_UNLOCK_ANALYSIS_REQUEST: null,
  SKIPPING_SCHEDULED_TASK: null,

  UPLOADING_CODE_FILE: null,
  DELETING_CODE_FILE: null,
  UPDATING_CODE_FILE: null,

  CREATING_SUBMISSION: null,
  CHANGING_STATUS_SUBMISSION: null,
  UPLOADING_SUBMISSION_RESULT: null,
  DELETE_SUBMISSION_RESULT: null,
  UPDATING_TITLE: null,
  DATA_SOURCE_ACCEPTED: null,
  DATA_SOURCE_DECLINED: null,

  DATA_SOURCE_REGISTERED: null,
});

export {
  activityTypes,
};
export * from 'community/const/activity';