import * as React from 'react';
import {
  paths,
} from 'const/activity';
import {
  Link
} from 'arachne-ui-components';

function Study(props) {
  const { title, id } = props;

  return (<Link to={paths.studies(id)}>{title}</Link>);
}

export function StudyText(props) {
  const { title, id } = props;

  return title;
}

export default Study;  
