/*
  *
  * Copyright 2018 Observational Health Data Sciences and Informatics
  * Licensed under the Apache License, Version 2.0 (the "License");
  * you may not use this file except in compliance with the License.
  * You may obtain a copy of the License at
  *
  *     http://www.apache.org/licenses/LICENSE-2.0
  *
  * Unless required by applicable law or agreed to in writing, software
  * distributed under the License is distributed on an "AS IS" BASIS,
  * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  * See the License for the specific language governing permissions and
  * limitations under the License.
  *
  * Company: Odysseus Data Services, Inc.
  * Product Owner/Architecture: Gregory Klebanov
  * Authors: Alexander Saltykov
  * Created: June 25, 2018
  *
  */

import React from 'react';
import {
  Modal,
  Form,
  FormInput,
  } from 'arachne-ui-components';
import BEMHelper from 'services/BemHelper';

import './style.scss';

function EditDataNodeTitle(props) {
  const classes = new BEMHelper('edit-data-node-title');
  
  const fields = [
    {
      name: 'name',
      InputComponent: {
        component: FormInput,
        props: {
          mods: ['bordered'],
          placeholder: 'Name',
        },
      },
    },
  ];

  const submitBtn = {
    label: 'Update',
    loadingLabel: 'Updating...',
    mods: ['success', 'rounded'],
  };

  const cancelBtn = {
    label: 'Cancel',
  };

  return (
    <Modal modal={props.modal} title="Edit data node name">
      <div {...classes()}>        
        <Form
          fields={fields}
          submitBtn={submitBtn}
          cancelBtn={cancelBtn}
          onSubmit={props.doSubmit}
          {...props}
        />        
      </div>
    </Modal>
  );
}

export default EditDataNodeTitle;

