import { createSelector } from 'reselect';
import { get } from 'services/Utils';
import { accessType as dsAccessType } from 'const/dataSource';
import { getReportAccessTypes, grantTypes } from 'modules/DataCatalog/const';

const getRawReportPermissions = state => get(state, 'dataCatalog.reportsPermissions.queryResult', [], 'Array');
const getDatasourceId = state => get(state, 'dataCatalog.dataSource.data.result.id');
const getDsAccessType = state => get(
  state,
  'dataCatalog.dataSource.data.result.accessType',
  dsAccessType.PUBLIC
);

const getAccessOptions = createSelector(
  getDsAccessType,
  (accessType) => getReportAccessTypes(accessType)
);

const getReportsPermissions = createSelector(
  getRawReportPermissions,
  rawData => rawData
);

const getInitialValues = createSelector(
  getDsAccessType,
  getReportsPermissions,
  (accessType, reportsPermissions) => {
    const initialValues = {};
    reportsPermissions.forEach((perm) => {
      initialValues[perm.report.id] = perm.grantType;
      if (accessType === dsAccessType.PUBLIC && perm.grantType === grantTypes.REJECTED) {
        initialValues[perm.report.id] = grantTypes.APPROVED;
      }
    });
    return initialValues;
  }
);

export default {
  getDatasourceId,
  getReportsPermissions,
  getInitialValues,
  getAccessOptions,
};
