import { createSelector } from 'reselect';
import { get } from 'services/Utils';
import ISelectorsBuilder from 'interfaces/ISelectorsBuilder';
import CommunityBuilder from 'community/modules/DataCatalog/components/DataSource/ViewEdit/View/AttributesList/selectors';
import dsInfoConvert from 'modules/DataCatalog/converters/dsInfoConvertor';

class EnterpriseDataCatalogViewAttrsSelectorsBuilder extends CommunityBuilder implements ISelectorsBuilder {

  /**
   * @override
   */
  getData(rawDs, attrList) {
    let { metadata, ...staticAttr } = rawDs;
    return dsInfoConvert({ ...staticAttr, ...metadata }, attrList);
  }

}

export default EnterpriseDataCatalogViewAttrsSelectorsBuilder;