import { createSelector, Selector } from 'reselect';
import { get } from 'services/Utils';
import { staticAttrList } from 'const/dataSource';
import { IEntityAttribute, IMetadataEntityAttributeDTO } from 'interfaces/models/EntityAttribute';
import convertToAttribute from 'converters/EntityAttributeDTOToIEntityAttributeConverter';
import CommunitySelector from 'community/modules/DataCatalog/selectors/DsAttrListSelector';

class DsAttrListSelector extends CommunitySelector {
  getMetadataAttrList(state: Object): Array<IMetadataEntityAttributeDTO> {
    return get(state, 'dataCatalog.dataSourceMetadataAttrs.queryResult.result');
  }

  getEnterpriseAttrList(metadataAttrList: IMetadataEntityAttributeDTO[], staticAttrList: IEntityAttribute[]): Array<IEntityAttribute> {
    let attrList = [...staticAttrList];
    if (Array.isArray(metadataAttrList)) {
      attrList = attrList.concat(metadataAttrList.map(convertToAttribute));
    }
    return attrList;
  }

  /**
   * @override
   */
  buildSelectorForGetAttrList(): Selector<Object, Array<IEntityAttribute>> {
    return createSelector<Object, Array<IMetadataEntityAttributeDTO>, Array<IEntityAttribute>, Array<IEntityAttribute>>(
      this.getMetadataAttrList,
      super.getAttrList,
      this.getEnterpriseAttrList
    );
  }

}

export default DsAttrListSelector;
