import keyMirror = require('keymirror');
import { forms as communityForms, modals as communityModals } from 'community/modules/InsightsLibrary/const';

const privacyTypes = [
  {
    label: 'Public',
    value: 'PUBLIC',
  },
  {
    label: 'Private',
    value: 'PRIVATE',
  },
];

const accessTypes = keyMirror({
  PUBLIC: null,
  PRIVATE: null,
});

const insightAccessOptions = [
  {
    label: 'Private',
    value: 'PRIVATE',
    tooltip: 'Allow access for selected users only',
    tooltipConf: 'left',
  },
  {
    label: 'Public',
    value: 'PUBLIC',
    tooltip: 'Allow access for everyone',
    tooltipConf: 'left',
  },
];

const modals = {
  ...communityModals,
  ...keyMirror({
    accessSettings: null,
  })
};

const forms = {
  ...communityForms,
  ...keyMirror({
    accessSettingsAdd: null,
  })
};

export * from 'community/modules/InsightsLibrary/const';
export {
  accessTypes,
  insightAccessOptions,
  privacyTypes,
  forms,
  modals,
};