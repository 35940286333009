/*
 *
 * Copyright 2018 Odysseus Data Services, inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Company: Odysseus Data Services, Inc.
 * Product Owner/Architecture: Gregory Klebanov
 * Authors: Pavel Grafkin, Alexander Saltykov, Vitaly Koulakov, Anton Gackovka, Alexandr Ryabokon, Mikhail Mironov
 * Created: April 10, 2017
 *
 */

import React from 'react';
import BEMHelper from 'services/BemHelper';
import { FormFileInput, Button, Form } from 'arachne-ui-components';

require('./style.scss');

function ModalFilesUploader(props) {
  const classes = new BEMHelper('modal-files-upload');
  const fields = [{
    name: 'files',
    InputComponent: {
      component: FormFileInput,
      props: {
        multiple: typeof props.multiple !== 'undefined' ? props.multiple : true,
        mods: ['bordered'],
        placeholder: 'Browse document',
        filePlaceholder: 'Label',
        dropzonePlaceholder: 'Drag and drop document',
      },
    }
  }];
  const submitBtn = {
    label: 'Add',
    loadingLabel: 'Adding...',
    mods: ['success', 'rounded'],
    disabled: !props.canSubmit,
  };

  return <div {...classes()}>
    <Form
      {...props}
      onSubmit={props.doSubmit}
      fields={fields}
      submitBtn={submitBtn}
    />
  </div>;
}

export default ModalFilesUploader;
