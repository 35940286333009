import CommunityBuilder from 'community/modules/AnalysisExecution/components/ViewEditAnalysis/ModalCreateCode/FilesUpload/container';
import { get } from 'services/Utils';
import mimeTypes from 'const/mimeTypes';
import { ModalUtils } from 'arachne-ui-components';
import { modal } from 'modules/AnalysisExecution/const';

export default class FileUploadBuilder extends CommunityBuilder {
  getMapDispatchToProps() {
    return {
      ...super.getMapDispatchToProps(),
      showPackratFiles: (uuid, label, analysisId, entryPoint) =>
        ModalUtils.actions.toggle(
          modal.packratBundle,
          true,
          { uuid, label, analysisId, entryPoint }
        ),
    };
  }

  mergeProps(stateProps, dispatchProps, ownProps) {
    const superProps = super.mergeProps(stateProps, dispatchProps, ownProps);

    return {
      ...superProps,
      doSubmit(data) {
        const submitPromise = superProps.doSubmit(data);
        submitPromise.then((results) => {
          const uploadedFile = get(results, '[0].result[0]');
          if (results.length === 1 && uploadedFile.docType === mimeTypes.packrat && stateProps.analysisFiles.length === 0) {
            dispatchProps.showPackratFiles(
              uploadedFile.uuid,
              uploadedFile.label,
              stateProps.analysisId
            );
          }
        });

        return submitPromise;
      },
    };
  }
}
