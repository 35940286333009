import * as React from 'react';
import * as moment from 'moment-timezone';
import BEMHelper from 'services/BemHelper';

function Date(props) {
  const { timestamp } = props;

  return (<span>{moment(timestamp).format('MM/DD/YYYY')}</span>);
}

export function DateText(props) {
  const { timestamp } = props;

  return `${moment(timestamp).format('MM/DD/YYYY')}`;
}

export default Date;
