/*
 *
 * Copyright 2018 Odysseus Data Services, inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Company: Odysseus Data Services, Inc.
 * Product Owner/Architecture: Gregory Klebanov
 * Authors: Pavel Grafkin, Alexander Saltykov, Vitaly Koulakov, Anton Gackovka, Alexandr Ryabokon, Mikhail Mironov
 * Created: July 13, 2017
 *
 */

// @ts-check
import { createSelector } from 'reselect';
import { get } from 'services/Utils';

export default class SelectorsBuilder {

  getInvitations(state, studyId) {
    const invitations = get(state, 'portal.invitation.list.queryResult.result', [], 'Array');
    return invitations.filter(invitation => get(invitation, 'entity.id') === studyId);
  }

  getPendingInvitation(invitations) {
    return (invitations && invitations.length) ? invitations[0] : null;
  }

  buildSelectorForInvitation() {
    return createSelector(
      [this.getInvitations],
      this.getPendingInvitation
    );
  }

  build() {
    return {
      getInvitation: this.buildSelectorForInvitation(),
    };
  }
}
