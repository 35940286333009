import * as React from 'react';
import {
  paths,
} from 'const/activity';
import {
  Link
} from 'arachne-ui-components';

function DataSource(props) {
  const { name, id } = props;

  return (<Link to={paths.dataSources(id)}>{name}</Link>);
}

export function DataSourceText(props) {
  const { name, id } = props;

  return name;
}

export default DataSource;  
