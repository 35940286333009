import { Component } from 'react';
import ducks from 'modules/DataCatalog/ducks';
import { get } from 'services/Utils';
import DataCatalogListBuilder, { DataCatalogStatefulList } from 'community/modules/DataCatalog/components/DataSource/List/container';
import ConnectedComponentBuilder from 'interfaces/ConnectedComponentBuilder';

interface IStatefulListProps {
  loadDsList: Function;
  searchStr: string;
}

interface IEnterpriseStatefulListDispatchProps {
	loadDsAttrs: Function;
}

interface IEnterpriseStatefulViewEditProps extends IStatefulListProps, IEnterpriseStatefulListDispatchProps {};

class EnterpriseStatefulList
  extends DataCatalogStatefulList 
  implements Component<any, any> {
  
  componentWillReceiveProps(nextProps) {
    super.componentWillReceiveProps(nextProps);
    if (this.props.searchStr !== nextProps.searchStr) {
      this.props.loadDsAttrs();
    }
  }
}

class EnterpriseDataCatalogListBuilder extends DataCatalogListBuilder implements ConnectedComponentBuilder {

  getComponent() {
    return EnterpriseStatefulList;
  }

  mapStateToProps(state) {
    const communityProps = super.mapStateToProps(state);

    return {
      ...communityProps,
      isLoading: communityProps.isLoading || get(state, 'dataCatalog.dataSourceMetadataAttrs.isLoading', false),
    };
  }

  getMapDispatchToProps() {
    return {
      ...super.getMapDispatchToProps(),
      loadDsAttrs: ducks.actions.dataSourceMetadataAttrs.query,
    };
  }

  getFetchers({ state }) {
    return {
      ...super.getFetchers({ state }),
      loadDsAttrs: ducks.actions.dataSourceMetadataAttrs.query,
    };
  }
  
}

export default EnterpriseDataCatalogListBuilder;
