/*
 *
 * Copyright 2018 Odysseus Data Services, inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Company: Odysseus Data Services, Inc.
 * Product Owner/Architecture: Gregory Klebanov
 * Authors: Pavel Grafkin, Alexander Saltykov, Vitaly Koulakov, Anton Gackovka, Alexandr Ryabokon, Mikhail Mironov
 * Created: January 17, 2018
 *
 */

import Duck from 'services/Duck';
import { apiPaths } from 'modules/AnalysisExecution/const';
import { combineReducers } from 'redux';

const analysis = new Duck({
  name: 'AE_SUBMISSION_SUMMARY_ANALYSIS',
  urlBuilder: apiPaths.shortAnalysis,
});
const submission = new Duck({
  name: 'AE_SUBMISSION_SUMMARY_SUBMISSION',
  urlBuilder: apiPaths.submission,
});

export default {
  actions: {
    analysis: analysis.actions,
    submission: submission.actions,
  },
  reducer: combineReducers({
    analysis: analysis.reducer,
    submission: submission.reducer,
  }),
};
