/*
 *
 * Copyright 2018 Odysseus Data Services, inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Company: Odysseus Data Services, Inc.
 * Product Owner/Architecture: Gregory Klebanov
 * Authors: Alex Cumarav, Vlad Belousov
 * Created: June 11, 2020
 *
 */


import { Component } from "react";
import { connect } from 'react-redux';
import { reduxForm } from "redux-form";
import { forms } from 'modules/Portal/const';

import ducks from "modules/Portal/ducks/index";

import presenter from './presenter';
import { get } from "services/Utils";

interface ISettingsState {
    isLoading: Boolean,
    initialValues: Object
}

interface ISettingsDispatch {
    loadSettings: Function,
    saveSettings: Function,
}

interface ISettingsProps extends ISettingsState, ISettingsDispatch {
}


class UserSettings extends Component<ISettingsProps, {}> {

    componentDidMount() {
        this.props.loadSettings();
    }

    render() {

        return (presenter(this.props));
    }
}

function mapStateToProps(state: Object) {
    let userSettings = get(state, 'portal.enterpriseUserSettings.data', {emailOnResults: null});
    return {
        isLoading: get(state, 'portal.enterpriseUserSettings.isLoading', false) || get(state, 'portal.userSettings.isUpdating', false),
        initialValues: userSettings
    };
}

const mapDispatchToProps = {
    loadSettings: ducks.actions.enterpriseUserSettings.find,
    saveSettings: ducks.actions.enterpriseUserSettings.create,
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
    };
}

function formSubmitHandler(values, dispatch, props) {
    props.saveSettings(null, values).then(props.loadSettings);
}

const UserSettingsForm = reduxForm({
    form: forms.userSettings,
    enableReinitialize: true,
    onSubmit: formSubmitHandler,
})<any>(UserSettings)

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(UserSettingsForm);