import { createSelector } from 'reselect';
import { get } from 'services/Utils';
import ISelectorsBuilder from 'interfaces/ISelectorsBuilder';
import CommunityBuilder from 'community/modules/DataCatalog/components/DataSource/List/selectors';
import dsInfoConvert from 'modules/DataCatalog/converters/dsInfoConvertor';

class EnterpriseDataCatalogListSelectorsBuilder extends CommunityBuilder implements ISelectorsBuilder {

  /**
   * @override
   */
  getDataSourceList(rawDataSourceList, attrList) {
    const columns = attrList.filter(attr => attr.showInList);
    return rawDataSourceList.map((ds) => {
      let { metadata, ...staticAttr } = ds;
      return dsInfoConvert({ ...staticAttr, ...metadata }, columns);
    });
  }

}

export default EnterpriseDataCatalogListSelectorsBuilder;