import * as React from 'react';
import {
  activityTypes,
  apiPaths,
  participantRoles,
  paths,
  submissionStatusTitles
} from 'const/activity';
import {get} from 'services/Utils';

import Document, { DocumentText } from '../Document/presenter';
import User, { UserText } from '../User/presenter';
import Privacy, { PrivacyText } from '../Privacy/presenter';
import Date, { DateText } from '../Date/presenter';
import LabelFiles, { LabelFilesText } from '../LabelFiles/presenter';
import Analysis, { AnalysisText } from '../Analysis/presenter';
import Text from '../Text/presenter';
import DataSource, { DataSourceText } from '../DataSource/presenter';
import Study, { StudyText } from '../Study/presenter';

interface ITitleParams {
  type: string;
  entities: any;
  isFullTitle: boolean;
  isImpersonal: boolean;
}

function Title(params: ITitleParams) {
  const { type, entities, isFullTitle, isImpersonal } = params;
  switch (type) {
    // data source
    case activityTypes.ADDING_DATA_SOURCE:
      return <span>
        added new <DataSource { ...entities.DATA_SOURCE } /> datasource {isFullTitle &&
          <span>to study <Study {...entities.STUDY} /></span>
        }
      </span>;
    case activityTypes.CREATING_VIRTUAL_DATA_SOURCE:
      return <span>
        created new <DataSource { ...entities.DATA_SOURCE } /> virtual datasource {isFullTitle &&
          <span>to study <Study {...entities.STUDY} /></span>
        }
        </span>;
    case activityTypes.DELETING_DATA_SOURCE:
      return <span>
        removed datasource <Text { ...entities.STRING } /> {isFullTitle &&
          <span>from study <Study {...entities.STUDY} /></span>
        }
      </span>;
    case activityTypes.ADDING_DATA_SOURCE_OWNER:
      return <span>
        invited datasource owner {isFullTitle &&
          <span>to study <Study {...entities.STUDY} /></span>
        }
      </span>;
    case activityTypes.ADDING_OWNER_TO_VIRTUAL_DATA_SOURCE:
      return <span>
        added owner to virtual data source <DataSource { ...entities.DATA_SOURCE } />
        {isFullTitle &&
          <span>in study <Study {...entities.STUDY} /></span>
        }
      </span>;
    case activityTypes.DATA_SOURCE_ACCEPTED:
      return <span>
        is now collaborating as a datasource owner {isFullTitle &&
          <span>in study <Study {...entities.STUDY} /></span>
        }
      </span>;
    case activityTypes.DATA_SOURCE_DECLINED:
      return <span>
        refused to collaborate as a datasource owner {isFullTitle &&
          <span>in study <Study {...entities.STUDY} /></span>
        }
      </span>;

    // analysis
    case activityTypes.CREATING_ANALYSIS:
      return <span>
        created analysis {entities.ANALYSIS
          ? <Analysis {...entities.ANALYSIS} />
          : <Text {...entities.ANALYSIS_TITLE} />
        } {isFullTitle &&
          <span>in study <Study {...entities.STUDY} /></span>
        }
      </span>;
    case activityTypes.DELETING_ANALYSIS:
      return <span>
        deleted analysis <Text {...entities.STRING} /> {isFullTitle &&
          <span>from study <Study {...entities.STUDY} /></span>
        }
      </span>;
    case activityTypes.UPDATING_ANALYSIS:
      return <span>
        updated analysis <Analysis {...entities.ANALYSIS} />
      </span>;
    case activityTypes.UPDATING_ANALYSIS_TITLE:
      return <span>
        renamed analysis <Analysis {...entities.ANALYSIS} />
      </span>;
    case activityTypes.UPDATING_ANALYSIS_DESCRIPTION:
      return <span>
        changed <Analysis {...entities.ANALYSIS} /> analysis description
      </span>;
    case activityTypes.UPDATING_ANALYSIS_TYPE:
      return <span>
        changed analysis <Analysis {...entities.ANALYSIS} /> type to
      </span>;
    case activityTypes.LOCKING_ANALYSIS:
      return <span>
        {entities.BOOLEAN.value === true ? 'locked' : 'unlocked' } analysis <Analysis {...entities.ANALYSIS} />
      </span>;
    case activityTypes.SENDING_UNLOCK_ANALYSIS_REQUEST:
      return <span>
        requested unlock for <Analysis {...entities.ANALYSIS} />
      </span>;
    case activityTypes.PROCESSING_UNLOCK_ANALYSIS_REQUEST:
      return <span>
        {entities.BOOLEAN.value === true ? 'accepted' : 'declined' } your unlock request for <Analysis {...entities.ANALYSIS} />
      </span>;
    case activityTypes.SKIPPING_SCHEDULED_TASK:
      return isImpersonal
        ? <span>
             Scheduled job in <Analysis {...entities.ANALYSIS}/> can not be executed
          </span>
        : <span>
             scheduled job in <Analysis {...entities.ANALYSIS}/> which can not be executed
          </span>;

    // code files
    case activityTypes.UPLOADING_CODE_FILE:
      return <span>
        uploaded new file(s) to analysis <Analysis {...entities.ANALYSIS} />
      </span>;
    case activityTypes.UPDATING_CODE_FILE:
      return <span>
        updated code file for analysis <Analysis {...entities.ANALYSIS} />
      </span>;
    case activityTypes.DELETING_CODE_FILE:
      return <span>
        removed code file from analysis <Analysis {...entities.ANALYSIS} />
      </span>;

    // submissions
    case activityTypes.CREATING_SUBMISSION:
      return <span>
        posted submission #{get(entities.SUBMISSION, 'order')} in <Analysis {...entities.ANALYSIS} /> analysis
      </span>;
    // can be impersonal
    case activityTypes.CHANGING_STATUS_SUBMISSION:
      const defaultCaption = () => isImpersonal ? 'in new status' : 'updated';
      const statusTitle = submissionStatusTitles[entities.SUBMISSION_STATUS.value] || defaultCaption;

      return isImpersonal
        ? <span>
            Submission #
            {get(entities.SUBMISSION, 'order')} in <Analysis {...entities.ANALYSIS}
            /> analysis is {statusTitle(true)}
          </span>
        : <span>
            {statusTitle(false)} submission #
            {get(entities.SUBMISSION, 'order')} in <Analysis {...entities.ANALYSIS} /> analysis
          </span>;
    // can be impersonal
    case activityTypes.UPLOADING_SUBMISSION_RESULT:
      return isImpersonal
      ? <span>
          Results available for submission #
          {get(entities.SUBMISSION, 'order')} in <Analysis {...entities.ANALYSIS} /> analysis
        </span>
      : <span>
          added result file to submission #
          {get(entities.SUBMISSION, 'order')} in <Analysis {...entities.ANALYSIS} /> analysis
        </span>;
    case activityTypes.DELETE_SUBMISSION_RESULT:
      return isImpersonal
      ? <span>
          Result file was removed from submission #
          {get(entities.SUBMISSION, 'order')} in <Analysis {...entities.ANALYSIS} /> analysis
        </span>
      : <span>
          deleted result file from submission #
          {get(entities.SUBMISSION, 'order')} in <Analysis {...entities.ANALYSIS} /> analysis
        </span>;

    // study
    case activityTypes.CREATING:
      return <span>
        created study {isFullTitle &&
          <Study {...entities.STUDY} />
        }
      </span>;
    case activityTypes.UPLOADING_FILE:
      return <span>
        added document {isFullTitle &&
          <span>to study <Study {...entities.STUDY} /></span>
        }
      </span>;
    case activityTypes.DELETING_FILE:
      return <span>
        removed document {isFullTitle &&
          <span>to study <Study {...entities.STUDY} /></span>
        }
      </span>;
    case activityTypes.ADDING_CONTRIBUTOR:
      return <span>
        added a new participant {isFullTitle &&
          <span>to study <Study {...entities.STUDY} /></span>
        }
      </span>;
    case activityTypes.CHANGING_CONTRIBUTOR_ROLE:
      return <span>
        changed participant role to <Text value={participantRoles[entities.STRING.value]} /> {isFullTitle &&
          <span>in study <Study {...entities.STUDY} /></span>
        }
      </span>;
    case activityTypes.DELETING_PARTICIPANT:
      return <span>
        removed participant {isFullTitle &&
          <span>from study <Study {...entities.STUDY} /></span>
        }
      </span>;
    case activityTypes.INVITATION_ACCEPTED:
      return <span>
        is now collaborating {isFullTitle &&
          <span>to study <Study {...entities.STUDY} /></span>
        }
      </span>;
    case activityTypes.INVITATION_DECLINED:
      return <span>
        declined an invitation {isFullTitle &&
          <span>to study <Study {...entities.STUDY} /></span>
        }
      </span>;
    case activityTypes.UPDATING_END_DATE:
      return <span>
        changed end date {isFullTitle &&
          <span>in study <Study {...entities.STUDY} /></span>
        } to
      </span>;
    case activityTypes.UPDATING_OBJECTIVE:
      return <span>
        updated objective {isFullTitle &&
          <span>in study <Study {...entities.STUDY} /></span>
        }
      </span>;
    case activityTypes.UPDATING_PRIVACY:
      return <span>
        changed privacy settings {isFullTitle &&
          <span>in study <Study {...entities.STUDY} /></span>
        } to
      </span>;
    case activityTypes.UPDATING_START_DATE:
      return <span>
        changed start date {isFullTitle &&
          <span>in study <Study {...entities.STUDY} /></span>
        } to
      </span>;
    case activityTypes.UPDATING_STATUS:
      return <span>
        changed study {isFullTitle &&
          <Study {...entities.STUDY} />
        } status to
      </span>;
    case activityTypes.UPDATING_TYPE:
      return <span>
        changed study {isFullTitle &&
          <Study {...entities.STUDY} />
        } type to
      </span>;
    case activityTypes.UPDATING_TITLE:
      return <span>
        renamed study {isFullTitle &&
          <Study {...entities.STUDY} />
        } to
      </span>;
    case activityTypes.DATA_SOURCE_REGISTERED:
      return <span>
        Data source <DataSource {...entities.DATA_SOURCE} /> was registered.
      </span>;
    default: return null;
  }
}

export function TitleText(params: ITitleParams) {
  const { type, entities, isFullTitle, isImpersonal } = params;

  switch (type) {
    // data source
    case activityTypes.ADDING_DATA_SOURCE:
      return `added new ${DataSourceText(entities.DATA_SOURCE)} datasource ${isFullTitle ? `to study ${StudyText(entities.STUDY)}` : ''}`;

    case activityTypes.CREATING_VIRTUAL_DATA_SOURCE:
      return `created new ${DataSourceText(entities.DATA_SOURCE)} virtual datasource ${isFullTitle ? `in study ${StudyText(entities.STUDY)}` : ''}`;

    case activityTypes.DELETING_DATA_SOURCE:
      return `removed datasource ${entities.STRING} ${isFullTitle ? `from study ${StudyText(entities.STUDY)}` : ''}>`;

    case activityTypes.ADDING_DATA_SOURCE_OWNER:
      return `invited datasource owner ${isFullTitle ? `to study ${StudyText(entities.STUDY)}` : ''}`;
      
    case activityTypes.ADDING_OWNER_TO_VIRTUAL_DATA_SOURCE:
      return `added owner to virtual data source ${DataSourceText(entities.DATA_SOURCE)} ${isFullTitle ? `in study ${StudyText(entities.STUDY)}` : ''}`;

    case activityTypes.DATA_SOURCE_ACCEPTED:
      return `is now collaborating as a datasource owner ${isFullTitle ? `in study ${StudyText(entities.STUDY)}` : ''}`;

    case activityTypes.DATA_SOURCE_DECLINED:
      return `refused to collaborate as a datasource owner ${isFullTitle ? `in study ${StudyText(entities.STUDY)}` : ''}`;

    // analysis
    case activityTypes.CREATING_ANALYSIS:
      return `created analysis ${entities.ANALYSIS
          ? AnalysisText(entities.ANALYSIS)
          : entities.ANALYSIS_TITLE
        } ${isFullTitle ? `in study ${StudyText(entities.STUDY)}` : ''}`;

    case activityTypes.DELETING_ANALYSIS:
      return `deleted analysis ${entities.STRING}${isFullTitle ? `from study ${StudyText(entities.STUDY)}` : ''}`;

    case activityTypes.UPDATING_ANALYSIS:
      return `updated analysis ${AnalysisText(entities.ANALYSIS)}`;

    case activityTypes.UPDATING_ANALYSIS_TITLE:
      return `renamed analysis ${AnalysisText(entities.ANALYSIS)}`;

    case activityTypes.UPDATING_ANALYSIS_DESCRIPTION:
      return `changed ${AnalysisText(entities.ANALYSIS)} description`;

    case activityTypes.UPDATING_ANALYSIS_TYPE:
      return `changed analysis ${AnalysisText(entities.ANALYSIS)} to`;

    case activityTypes.LOCKING_ANALYSIS:
      return `${entities.BOOLEAN.value === true ? 'locked' : 'unlocked' } analysis ${AnalysisText(entities.ANALYSIS)}`;

    case activityTypes.SENDING_UNLOCK_ANALYSIS_REQUEST:
      return `requested unlock for ${AnalysisText(entities.ANALYSIS)}`;

    case activityTypes.PROCESSING_UNLOCK_ANALYSIS_REQUEST:
      return `${entities.BOOLEAN.value === true ? 'accepted' : 'declined' } your unlock request for ${AnalysisText(entities.ANALYSIS)}`;

    case activityTypes.SKIPPING_SCHEDULED_TASK:
      return isImpersonal
        ? `Scheduled job in ${AnalysisText(entities.ANALYSIS)} can not be executed`
        : `scheduled job in ${AnalysisText(entities.ANALYSIS)} which can not be executed`;

    // code files
    case activityTypes.UPLOADING_CODE_FILE:
      return `uploaded new file(s) to analysis ${AnalysisText(entities.ANALYSIS)}`;

    case activityTypes.UPDATING_CODE_FILE:
      return `updated code file for analysis ${AnalysisText(entities.ANALYSIS)}`;
    
    case activityTypes.DELETING_CODE_FILE:
      return `removed code file from analysis ${AnalysisText(entities.ANALYSIS)}`;

    // submissions
    
    case activityTypes.CREATING_SUBMISSION:
      return `posted submission #${get(entities.SUBMISSION, 'order')} in ${AnalysisText(entities.ANALYSIS)} analysis`;
    // can be impersonal
    
    case activityTypes.CHANGING_STATUS_SUBMISSION:
      const defaultCaption = () => isImpersonal ? 'in new status' : 'updated';
      const statusTitle = submissionStatusTitles[entities.SUBMISSION_STATUS.value] || defaultCaption;

      return isImpersonal
        ? `Submission #${get(entities.SUBMISSION, 'order')} in ${AnalysisText(entities.ANALYSIS)} analysis is ${statusTitle(true)}`
        : `${statusTitle(false)} submission #${get(entities.SUBMISSION, 'order')} in ${AnalysisText(entities.ANALYSIS)} analysis`;
    // can be impersonal
    
    case activityTypes.UPLOADING_SUBMISSION_RESULT:
      return isImpersonal
      ? `Results available for submission #${get(entities.SUBMISSION, 'order')} in ${AnalysisText(entities.ANALYSIS)} analysis`
      : `added result file to submission #${get(entities.SUBMISSION, 'order')} in ${AnalysisText(entities.ANALYSIS)} analysis`;
    
    case activityTypes.DELETE_SUBMISSION_RESULT:
      return isImpersonal
      ? `Result file was removed from submission #${get(entities.SUBMISSION, 'order')} in ${AnalysisText(entities.ANALYSIS)} analysis`
      : `deleted result file from submission #${get(entities.SUBMISSION, 'order')} in ${AnalysisText(entities.ANALYSIS)} analysis`;

    // study
    
    case activityTypes.CREATING:
      return `created study ${isFullTitle ? StudyText(entities.STUDY) : ''}`;
    
    case activityTypes.UPLOADING_FILE:
      return `added document ${isFullTitle ? `to study ${StudyText(entities.STUDY)}` : ''}`;
    
    case activityTypes.DELETING_FILE:
      return `removed document ${isFullTitle ? `from study ${StudyText(entities.STUDY)}` : ''}`;
    
    case activityTypes.ADDING_CONTRIBUTOR:
      return `added a new participant ${isFullTitle ? `to study ${StudyText(entities.STUDY)}` : ''}`;
    
    case activityTypes.CHANGING_CONTRIBUTOR_ROLE:
      return `changed participant role to ${participantRoles[entities.STRING.value]}${isFullTitle ? `in study ${StudyText(entities.STUDY)}` : ''}`;
    
    case activityTypes.DELETING_PARTICIPANT:
      return `removed participant ${isFullTitle ? `from study ${StudyText(entities.STUDY)}` : ''}`;
    
    case activityTypes.INVITATION_ACCEPTED:
      return `is now collaborating ${isFullTitle ? `to study ${StudyText(entities.STUDY)}` : ''}`;
    
    case activityTypes.INVITATION_DECLINED:
      return `declined an invitation ${isFullTitle ? `to study ${StudyText(entities.STUDY)}` : ''}`;
    
    case activityTypes.UPDATING_END_DATE:
      return `changed end date ${isFullTitle ? `in study ${StudyText(entities.STUDY)}` : ''}`;
    
    case activityTypes.UPDATING_OBJECTIVE:
      return `updated objective ${isFullTitle ? `in study ${StudyText(entities.STUDY)}` : ''}`;
    
    case activityTypes.UPDATING_PRIVACY:
      return `changed privacy settings ${isFullTitle ? `in study ${StudyText(entities.STUDY)}` : ''}`;
    
    case activityTypes.UPDATING_START_DATE:
      return `changed start date ${isFullTitle ? `in study ${StudyText(entities.STUDY)}` : ''} to `;
    
    case activityTypes.UPDATING_STATUS:
      return `changed study ${isFullTitle ? `to study ${StudyText(entities.STUDY)}` : ''} status to`;
    
    case activityTypes.UPDATING_TYPE:
      return `changed study ${isFullTitle ? `to study ${StudyText(entities.STUDY)}` : ''} type to`;
    
    case activityTypes.UPDATING_TITLE:
      return `renamed study ${isFullTitle ? `to study ${StudyText(entities.STUDY)}` : ''} to`;
    
    case activityTypes.DATA_SOURCE_REGISTERED:
      return `Data source ${DataSourceText(entities.DATA_SOURCE)} was registered.`;
      
    default: return '';
  }
}

export default Title;