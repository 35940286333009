import CommunityListCodeBuilder from 'community/modules/AnalysisExecution/components/ViewEditAnalysis/ListCode/container';
import { ModalUtils } from 'arachne-ui-components';
import { modal } from 'modules/AnalysisExecution/const';
import actions from 'actions';
import { get } from 'services/Utils';

export default class ListCodeBuilder extends CommunityListCodeBuilder {

  mapStateToProps(state) {
    const analysisData = get(state, 'analysisExecution.analysis.data.result');
    const isScheduled = get(analysisData, 'isScheduled');

    return {
      ...super.mapStateToProps(state),
      isScheduled,
    };
  }

  getMapDispatchToProps() {
    return {
      ...super.getMapDispatchToProps(),
      updateCode: actions.analysisExecution.codeBundle.selectExecutable,
      openScheduler: ModalUtils.actions.toggle.bind(null, modal.scheduler, true),
      showPackratFiles: (uuid, label, analysisId, entryPoint) =>
        ModalUtils.actions.toggle(modal.packratBundle, true, { uuid, label, analysisId, entryPoint }),
    };
  }

  mergeProps(stateProps, dispatchProps, ownProps) {
    return {
      ...super.mergeProps(stateProps, dispatchProps, ownProps),
      toggleExecutable(analysisCodeId, isExecutable) {
        dispatchProps
          .updateCode({ analysisId: stateProps.analysisId, analysisCodeId, isExecutable })
          .then(() => dispatchProps.loadAnalysis({ id: stateProps.analysisId }));
      },
      selectExecutable(uuid, label, entryPoint) {
        dispatchProps.showPackratFiles(uuid, label, stateProps.analysisId, entryPoint);
      },
    };
  }
}