import CommunitySelectorsBuilder from 'community/modules/AnalysisExecution/components/ViewEditAnalysis/ListCode/selectors';
import MimeTypes from 'const/mimeTypes';
import { get } from 'services/Utils';

export default class SelectorsBuilder extends CommunitySelectorsBuilder {
  getSelectableMimeTypes() {
    return [
      ...super.getSelectableMimeTypes(),
      MimeTypes.packrat,
    ];
  }

  getCode(analysis, code) {
    const converted = super.getCode(analysis, code);
    converted.isPackrat = get(code, 'docType', '').toLowerCase() === MimeTypes.packrat;

    return converted;
  }
}
