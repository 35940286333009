import communityDucks from 'community/modules/DataCatalog/ducks';
import reportsPermissions from './reportsPermissions';

export default {
    actions: {
        ...communityDucks.actions,
        reportsPermissions: reportsPermissions.actions,
    },
    reducer: {
        ...communityDucks.reducer,
        reportsPermissions: reportsPermissions.reducer,
    },
};
