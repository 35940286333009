import communityDucks from 'community/modules/StudyManager/ducks';

import recentActivity from './recentActivity';
import recentActivitySettings from './recentActivitySettings';

const actions = {
  recentActivity: recentActivity.actions,
  recentActivitySettings: recentActivitySettings.actions,
};

const loadStudyWActivities = (studyId) => {
  return (dispatch) => {
    dispatch(communityDucks.actions.study.query(studyId)); // TODO: change to find
    if (studyId) {
      dispatch(recentActivity.actions.find({ studyId }));
    }
  }
}

export default {
  actions: {
    ...actions,
    ...communityDucks.actions,
    study: {
      ...communityDucks.actions.study,
      query: loadStudyWActivities, // TODO: change to find. Hotfixed before release
    }
  },
  reducer: {
    ...communityDucks.reducer,
    recentActivity: recentActivity.reducer,
    recentActivitySettings: recentActivitySettings.reducer,
  },
};
