import Duck from 'community/services/Duck';
import { apiPaths } from 'modules/Admin/const';

const actionCoreName = 'AD_TENANTS_USERS';
const addToTenants = 'AD_PORTAL_USERS_ADD_TO_TENANTS';

const attributesDuck = new Duck({
  name: actionCoreName,
  urlBuilder: apiPaths.tenantUsers,
});

const portalUserAddToTenants = new Duck({
  name: addToTenants,
  urlBuilder: apiPaths.portalUsersAddToTenants,
});

const actions = {
  ...attributesDuck.actions,
  addToTenants: portalUserAddToTenants.actions.create,
};

const reducer = attributesDuck.reducer;

export default {
  actions,
  reducer,
};