import * as React from 'react';
import BEMHelper from 'services/BemHelper';
import { Form, FormInput } from 'arachne-ui-components';

import './style.scss';

export default function NewFile(props) {
  const classes = BEMHelper('modal-create-new-analysis-file');

  const fields = [
    {
      name: 'name',
      InputComponent: {
        component: FormInput,
        props: {
          mods: ['bordered'],
          placeholder: 'Name file',
          type: 'text',
        },
      },
    },
  ];

  const submitBtn = {
    label: 'Create',
    loadingLabel: 'Creating...',
    mods: ['success', 'rounded'],
  };

  return (
    <div {...classes()}>
      <Form
        mods="spacing-sm"
        fields={fields}
        submitBtn={submitBtn}
        onSubmit={props.doSubmit}
        {...props}
      />
    </div>
  );
}
