/*
 *
 * Copyright 2018 Odysseus Data Services, inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Company: Odysseus Data Services, Inc.
 * Product Owner/Architecture: Gregory Klebanov
 * Authors: Pavel Grafkin, Alexander Saltykov, Vitaly Koulakov, Anton Gackovka, Alexandr Ryabokon, Mikhail Mironov
 * Created: December 27, 2017
 *
 */

import { Component } from 'react';
import { isFat as isMimeTypeFat } from 'services/MimeTypeUtil';
import { get } from 'services/Utils';

export class FileLoader extends Component {
  componentWillMount() {
    this.loadData(this.props);
  }

  loadData(props) {
    const urlParams = props.urlParams;
    const queryParams = props.queryParams;
    props.loadFile({
      ...urlParams,
      query: { ...queryParams, withContent: false },
    }).then((result) => {
      if (!isMimeTypeFat(get(result, 'result.docType', '', 'String'))) {
        props.loadFile({
          ...urlParams,
          query: { ...queryParams, withContent: true },
        });
      }
    });
  }
}
