import keyMirror = require('keymirror');
import { form as communityForms } from 'community/modules/Portal/const';

import { apiPaths as communityApiPaths } from 'community/modules/Portal/const';

const apiPaths = {
  ...communityApiPaths,
  notifications: () => '/api/v1/user-management/notifications',
  notificationSubscription: () => '/user/topic/notifications',
  enterpriseUserSettings: () => '/api/v1/user-management/settings',
};

export * from 'community/modules/Portal/const';
export {
  apiPaths,
};

export const forms = {
  ...communityForms,
  ...keyMirror({
    userSettings: null,
  })
};
