import Duck from 'community/services/Duck';
import { apiPaths } from 'modules/Admin/const';

const actionCoreName = 'AD_TENANTS_DS';

const attributesDuck = new Duck({
  name: actionCoreName,
  urlBuilder: apiPaths.tenantDataSources,
});

const actions = attributesDuck.actions;
const reducer = attributesDuck.reducer;

export default {
  actions,
  reducer,
};