import { get } from 'services/Utils';
import CommunityBuilder from 'community/modules/DataCatalog/components/DataSource/ViewEdit/View/ModalInviteToStudy/container';
import ConnectedComponentBuilder from 'interfaces/ConnectedComponentBuilder';
import { accessType } from 'const/dataSource';
import { status as linkStatus } from 'const/entitiesLink';

class ModalInviteDSToStudyBuilder extends CommunityBuilder implements ConnectedComponentBuilder {

  mapStateToProps(state) {
    const isPublicDs = get(state, 'dataCatalog.dataSource.data.result.accessType') === accessType.PUBLIC;

    return {
      ...super.mapStateToProps(state),
      modalTitle: isPublicDs ? 'Attach data source' : 'Invite data source',
    };
  }

  mergeProps(stateProps, dispatchProps, ownProps) {
    return {
      ...super.mergeProps(stateProps, dispatchProps, ownProps),
      doSubmit({ studyId }) {
        const submitPromise = dispatchProps.inviteDataSource({
          studyId,
          dataSourceId: stateProps.dataSourceId,
        });

        const study = stateProps.studyOptions.find(({ value }) => value === studyId);
        let approvedImmediately = false;

        submitPromise
          .then(({ status }) => {
            approvedImmediately = status === linkStatus.APPROVED;
          })
          .then(() => dispatchProps.resetForm())
          .then(() => dispatchProps.closeModal())
          .then(() => dispatchProps.openConfirmDatasource({
            datasourceName: stateProps.name,
            study,
            approvedImmediately,
          }))
          .catch(() => {});

        return submitPromise;
      },
    };
  }

}

export default ModalInviteDSToStudyBuilder;
