import Duck from 'community/services/Duck';
import { apiPaths } from 'modules/Admin/const';

const actionCoreName = 'AD_METDATA_OPTIONS';

const optionsDuck = new Duck({
  name: actionCoreName,
  urlBuilder: apiPaths.updateOptions,
});

const optionDuck = new Duck({
  name: `${actionCoreName}_OPTION`,
  urlBuilder: (urlParams) => {
    return urlParams.remove
      ? apiPaths.removeOption(urlParams)
      : apiPaths.addOption(urlParams);
  },
});

const actions = optionsDuck.actions;
const reducer = optionsDuck.reducer;

export default {
  actions: {
    ...actions,
    updateBulk: actions.update,
    add: optionDuck.actions.create,
    delete: optionDuck.actions.delete,
  },
  reducer,
};