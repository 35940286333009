import * as React from 'react';
import CommunityModal from 'community/modules/AnalysisExecution/components/ViewEditAnalysis/ModalCreateCode/presenter';
import { submissionFileUploadModes as communityModes } from 'community/modules/AnalysisExecution/components/ViewEditAnalysis/ModalCreateCode/presenter';
import NewFile from './NewFile';

export const submissionFileUploadModes = {
  ...communityModes,
  CREATE: 'New file',
};

export default class ModalCreateCode extends CommunityModal {

  getSections() {
    const sections = [...super.getSections()];
    
    const newFileSection = {
      label: submissionFileUploadModes.CREATE,
      content: <NewFile />,
    };
    sections.splice(1, 0, newFileSection);

    return sections;
  };

}