/*
 *
 * Copyright 2018 Odysseus Data Services, inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Company: Odysseus Data Services, Inc.
 * Product Owner/Architecture: Gregory Klebanov
 * Authors: Pavel Grafkin, Alexander Saltykov, Vitaly Koulakov, Anton Gackovka, Alexandr Ryabokon, Mikhail Mironov
 * Created: May 11, 2017
 *
 */

import React from 'react';
import BEMHelper from 'services/BemHelper';
import { Form, FormTextarea } from 'arachne-ui-components';

require('./style.scss');

function NewComment(props) {
  const classes = new BEMHelper('insight-comment-new');
  const fields = [
    {
      name: 'comment',
      InputComponent: {
        component: FormTextarea,
        props: {
        	mods: ['small'],
          placeholder: 'New annotation',
        },
      },
    },
  ];

  const submitBtn = {
    label: 'Submit',
    loadingLabel: 'Sending...',
    mods: ['submit'],
  };

  const cancelBtn = {
    label: 'Cancel',
    mods: ['cancel'],
  };

  return (
    <div {...classes()}>
      <Form
        mods={['actions-inline']}
        fields={fields}
        submitBtn={submitBtn}
        onSubmit={props.onSubmit}
        cancelBtn={cancelBtn}
        onCancel={props.cancel}
        {...props}
      />
    </div>
  )
}

export default NewComment;
