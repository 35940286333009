import * as React from 'react';
import BEMHelper from 'services/BemHelper';
import {
  Avatar,
  Link
} from 'arachne-ui-components';
import * as moment from 'moment-timezone';
import {
  apiPaths,
  paths,
} from 'const/activity';

import Title, { TitleText } from './components/Title/presenter';
import Description, { DescriptionText } from './components/Description/presenter';

import './style.scss';
import { usDateTime } from 'const/formats';

function ActivityItem(props) {
  const classes = BEMHelper('activity-item');
  const {
    actionList,
    activityType,
    affectedEntities,
    date,
    sourceDate,
    user,
    isFullTitle,
    shown = true,
  } = props;
  const tooltipClasses = BEMHelper('tooltip');
  const dateHint = moment(sourceDate).tz(moment.tz.guess()).format(usDateTime);

  return (
    <li {...classes({ modifiers: { unread: !shown } })}>
      <div {...classes('userpic')}>
        <Avatar img={user ? apiPaths.userpic(user.id) : null}/>
      </div>
      <div {...classes('content')}>
        <span {...classes('heading')}>
          <div {...classes('action-type')}>
            {user
            ? <Link to={paths.user(user.id)} {...classes('author')}>
                {user.firstname} {user.lastname}
              </Link>
            : null
            }
            <Title
              type={activityType}
              entities={affectedEntities}
              isFullTitle={isFullTitle}
              isImpersonal={!user}
            />
          </div>
          <span
            {...classes('date', null, tooltipClasses().className)}
            aria-label={dateHint}
            data-tootik-conf={'left'}
          >
            {date}
          </span>
        </span>
        <div {...classes('description')}>
          <Description
            type={activityType}
            entities={affectedEntities}
          />
        </div>
        <div {...classes('actions')}>
          {actionList && actionList.map((action, key) =>
            <Link
              {...classes('action')}
              key={key}>
              {action.label}
            </Link>
            )
          }
        </div>
      </div>
    </li>
  )
}

export function getTextActivity(notification) {
  const {
    actionList,
    activityType,
    affectedEntities,
    date,
    sourceDate,
    user,
    isFullTitle,
    shown = true,
  } = notification;

  let title = `${user.firstname} ${user.lastname}`;
  const subtitle = TitleText({
    type: activityType,
    entities: affectedEntities,
    isFullTitle: isFullTitle,
    isImpersonal: !user,
  });
  let body = DescriptionText({
    type: activityType,
    entities: affectedEntities,
  });
  if (body === '') {
    body = subtitle;
  } else {
    title = `${title} ${subtitle}`;
  }

  return {
    title,
    body,
    icon: apiPaths.userpic(user.id),
  };
}

export default ActivityItem;