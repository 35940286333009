import * as React from 'react';
import BEMHelper from 'services/BemHelper';
import { Modal, LoadingPanel, ListItem, RadioButton, Button } from 'arachne-ui-components';
import FileInfo from 'components/FileInfo';

import './style.scss';

function ModalPackratBundle(props) {
  const {
    label,
    files,
    modal,
    close,
    selectExecutable,
    entryPoint,
    submit,
  } = props;
  const classes = BEMHelper('modal-packrat-bundle');

  return (
    <div {...classes()}>
      <Modal modal={modal} title={label} mods={['no-padding']}>
        <div {...classes('content')}>
          <div {...classes('label')}>
            Select entry point:
          </div>
          <div {...classes('files-wrapper')}>
            {files && files.map(file =>
              <ListItem {...classes('file')}>
                <RadioButton
                  {...classes('radiobutton')}
                  isChecked={entryPoint === file.fileName}
                  isDisabled={!['r', 'sql'].includes(file.docType.toLowerCase())}
                  onChange={() => selectExecutable(file.fileName)}
                />
                <div {...classes('main-info')}>
                  <FileInfo docType={file.docType} label={file.fileName} />
                </div>
              </ListItem>
            )}
          </div>
          <div {...classes('actions')}>
            <Button
              {...classes('btn')}
              mods={['success', 'rounded']}
              onClick={submit}
            >
              Attach
            </Button>
            <Button mods={['cancel', 'rounded']} onClick={close}>Cancel</Button>
          </div>
          <LoadingPanel active={props.isLoading} />
        </div>
      </Modal>
    </div>
  );
}

export default ModalPackratBundle;
