/*
 *
 * Copyright 2018 Odysseus Data Services, inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Company: Odysseus Data Services, Inc.
 * Product Owner/Architecture: Gregory Klebanov
 * Authors: Pavel Grafkin, Alexander Saltykov, Vitaly Koulakov, Anton Gackovka, Alexandr Ryabokon, Mikhail Mironov
 * Created: Apr 27, 2018
 *
 */

import { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'services/Utils';
import presenter from './presenter';
import actions from 'actions/index';
import Auth from 'services/Auth';

class UsernameField extends Component{

  constructor(props){
    super(props);
  }

  render(){
    return presenter({
      ...this.props,
    });
  }
}

function mapStateToProps(state) {
  return {
  };
}

const mapDispatchToProps = {
  queryPrincipal: actions.auth.principal.query,
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  return ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    edit: () => {
      Auth.clearUserRequest();
      dispatchProps.queryPrincipal();
    }
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(UsernameField);