/*
 *
 * Copyright 2018 Odysseus Data Services, inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Company: Odysseus Data Services, Inc.
 * Product Owner/Architecture: Gregory Klebanov
 * Authors: Pavel Grafkin, Alexander Saltykov, Vitaly Koulakov, Anton Gackovka, Alexandr Ryabokon, Mikhail Mironov
 * Created: May 05, 2017
 *
 */

import React, { Component } from 'react';
import BEMHelper from 'services/BemHelper';
import { TabbedPane } from 'arachne-ui-components';
import ListCode from '../ListCode';

// require('./style.scss');

function Sections(props) {
  const {
    codeFiles,
    codePageCount,
    codePageCurrent,
    path,
    resultFiles,
    resultPageCount,
    resultPageCurrent,
    toggleComments,
  } = props;
  const sections = [
    {
      label: 'Code files',
      content: <ListCode
        files={codeFiles}
        onSelect={toggleComments}
        pageCount={codePageCount}
        currentPage={codePageCurrent}
        pageKey="codePage"
        path={path}
      />,
    },
    {
      label: 'Results',
      content: <ListCode
        files={resultFiles}
        onSelect={toggleComments}
        pageCount={resultPageCount}
        currentPage={resultPageCurrent}
        pageKey="resultPage"
        path={path}
      />,
    },
  ];

  return (
    <TabbedPane
      modsContent={['detached-content']}
      sections={sections}
    />
  );
}

export default Sections;
