/*
 *
 * Copyright 2018 Odysseus Data Services, inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Company: Odysseus Data Services, Inc.
 * Product Owner/Architecture: Gregory Klebanov
 * Authors: Pavel Grafkin, Alexander Saltykov, Vitaly Koulakov, Anton Gackovka, Alexandr Ryabokon, Mikhail Mironov
 * Created: May 05, 2017
 *
 */

import React, { Component } from 'react';
import BEMHelper from 'services/BemHelper';
import { Toolbar, Link } from 'arachne-ui-components';
import { paths, statusColors } from 'modules/AnalysisExecution/const';
import Actions from '../Actions/index';

require('./style.scss');

function InsightToolbar(props) {
  const classes = new BEMHelper('insight-toolbar');
  const {
    insightTitle,
    backUrl,
    breadcrumbList,
    editTitle,
    isEditable,
  } = props;

  return (
    <Toolbar
      caption={insightTitle}
      breadcrumbList={breadcrumbList}
      backUrl={backUrl}
      onEdit={editTitle}
      isEditable={isEditable}>
      <Actions isEditable={isEditable} />
    </Toolbar>
  );
}

export default InsightToolbar;
