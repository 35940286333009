/*
 *
 * Copyright 2018 Odysseus Data Services, inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Company: Odysseus Data Services, Inc.
 * Product Owner/Architecture: Gregory Klebanov
 * Authors: Pavel Grafkin, Alexander Saltykov, Vitaly Koulakov, Anton Gackovka, Alexandr Ryabokon, Mikhail Mironov
 * Created: October 05, 2017
 *
 */

import Duck from 'services/Duck';

const coreName = 'AE_ANALYSIS_INSIGHT_COMMENT_FILE';

const duck = new Duck({
  name: coreName,
  urlBuilder: () => {},
});

function setSelectedFile(data) {
  return {
    type: `${coreName}_FIND_FULFILLED`,
    payload: data,
  };
}

function selectFile(data) {
  return dispatch => dispatch(setSelectedFile(data));
}

function unload() {
  return dispatch => dispatch(setSelectedFile(null));
}

export default {
  actions: {
    select: selectFile,
    unload,
  },
  reducer: duck.reducer,
};
