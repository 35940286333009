/*
 *
 * Copyright 2018 Odysseus Data Services, inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Company: Odysseus Data Services, Inc.
 * Product Owner/Architecture: Gregory Klebanov
 * Authors: Alex Cumarav, Vlad Belousov
 * Created: June 11, 2020
 *
 */


import * as React from 'react';
import { Form, FormToggle, Panel } from 'arachne-ui-components';
import BEMHelper from "services/BemHelper";

import './style.scss';

const classes = BEMHelper('portal-settings-user-settings');

function UserSettingsPresenter(props) {

    const fields = [
        {
            name: 'emailOnResults',
            InputComponent: {
                component: FormToggle,
                props: {
                    label: 'Notify on Analysis Results',
                    isManaged: true
                }
            }
        }];

    const submitBtn = {
        label: 'Save',
        loadingLabel: 'Loading...',
        mods: ['success', 'rounded'],
    };

    return (
        <div>
            <Panel
                {...classes()}
                title="User settings"
            >
                <Form
                    {...props}
                    fields={fields}
                    submitBtn={submitBtn}
                    {...classes('form')}
                />
            </Panel>
        </div>
    );
}

export default UserSettingsPresenter;