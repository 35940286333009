import { IEntityAttribute, IMetadataEntityAttributeDTO } from '../interfaces/models/EntityAttribute';

export default function (attribute: IMetadataEntityAttributeDTO): IEntityAttribute {
  return {
    label: attribute.name,
    name: `${attribute.id}`,
    type: attribute.type,
    options: Object.keys(attribute.options).map(key => ({
      label: attribute.options[key],
      value: parseInt(key),
    })),
    showInList: attribute.showInList,
    faceted: attribute.faceted,
    min: attribute.currentMinValue,
    max: attribute.currentMaxValue,
  };
};
