import ducks from 'modules/DataCatalog/ducks';
import { get } from 'services/Utils';
import DataCatalogListActionsBuilder from 'community/modules/DataCatalog/components/DataSource/List/Actions/container';
import ConnectedComponentBuilder from 'interfaces/ConnectedComponentBuilder';

class EnterpriseDataCatalogListActionsBuilder extends DataCatalogListActionsBuilder implements ConnectedComponentBuilder {

  getMapDispatchToProps() {
    return {
      ...super.getMapDispatchToProps(),
      loadDsAttrs: ducks.actions.dataSourceMetadataAttrs.query,
    };
  }
}

export default EnterpriseDataCatalogListActionsBuilder;
