import { Component, PropTypes } from 'react';
import { Utils, get, ContainerBuilder } from 'services/Utils';
import actions from 'actions';
import { ModalUtils } from 'arachne-ui-components';
import { modal } from 'modules/AnalysisExecution/const';
import presenter from './presenter';

interface IFile {
  fileName: string;
  docType: string;
}
interface IModalPackratBundleInnerState {
  entryPoint: string;
}
interface IModalPackratBundleState extends IModalPackratBundleInnerState {
  uuid: string;
  analysisId: number;
  isOpened: boolean;
  isLoading: boolean;
  files: Array<IFile>;
}
interface IModalPackratBundleDispatch {
  close: Function;
  loadBundle: Function;
  createWrapperForSelectedEntryPoint: Function;
  loadAnalysis: Function;
  updateCode: Function;
}
interface IModalPackratBundleProps extends IModalPackratBundleState, IModalPackratBundleDispatch {

}

class ModalPackratBundle extends Component<IModalPackratBundleProps, {}> {
  state: IModalPackratBundleInnerState;

  static get propTypes() {
    return {
      isOpened: PropTypes.bool,
      loadBundle: PropTypes.func,
      analysisId: PropTypes.number,
      uuid: PropTypes.string,
      createWrapperForSelectedEntryPoint: PropTypes.func,
      loadAnalysis: PropTypes.func,
      close: PropTypes.func,
      updateCode: PropTypes.func,
      entryPoint: PropTypes.string,
    };
  }

  constructor() {
    super();
    this.state = {
      entryPoint: null,
    };
    this.selectExecutable = this.selectExecutable.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentWillReceiveProps(nextProps: IModalPackratBundleState) {
    if (this.props.isOpened === false && nextProps.isOpened === true) {
      this.props.loadBundle({
        analysisId: nextProps.analysisId,
        fileUuid: nextProps.uuid
      });
    }
  }

  selectExecutable(filename) {
    this.setState({
      entryPoint: filename,
    });
  }

  submit() {
    this.props.createWrapperForSelectedEntryPoint({
        analysisId: this.props.analysisId,
        fileUuid: this.props.uuid,
      },
      {
        entryPoint: this.state.entryPoint,
      }
    )
      .then(() => this.props.loadAnalysis({ id: this.props.analysisId }))
      .then(this.props.close);
  }

  render() {
    return presenter({
      ...this.props,
      selectExecutable: this.selectExecutable,
      submit: this.submit,
      entryPoint: this.state.entryPoint || this.props.entryPoint,
    });
  }
}

export default class ModalPackratBundleBuilder extends ContainerBuilder {
  getComponent() {
    return ModalPackratBundle;
  }

  getModalParams() {
    return {
      name: modal.packratBundle,
    };
  }

  mapStateToProps(state: Object): IModalPackratBundleState {
    const isOpened = get(state, 'modal.packratBundle.isOpened', false);
    const isLoading = get(state, 'analysisExecution.codeBundle.isLoading', false);

    const data = get(state, 'modal.packratBundle.data', {
      uuid: -1,
      analysisId: -1,
      entryPoint: '',
    }, 'Object');

    const files = get(state, 'analysisExecution.codeBundle.queryResult.result', [], 'Array');
    data.label = 'Attach Packrat Runner';

    return {
      ...data,
      isOpened,
      isLoading,
      files,
    };
  }

  getMapDispatchToProps(): IModalPackratBundleDispatch {
    return {
      close: () => ModalUtils.actions.toggle(modal.packratBundle, false),
      loadBundle: actions.analysisExecution.codeBundle.query,
      createWrapperForSelectedEntryPoint: actions.analysisExecution.codeBundle.codeWrapper.update,
      loadAnalysis: actions.analysisExecution.analysis.find,
      updateCode: actions.analysisExecution.analysisCode.update,
    }
  }
}
