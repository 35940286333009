import * as React from 'react';
import BEMHelper from 'services/BemHelper';
import LabelUser from 'components/LabelUser';

function User(props) {
  const { firstname, lastname, id } = props;
  return <LabelUser user={ {id, name: `${firstname} ${lastname}`} } />;
}

export function UserText(props) {
  const { firstname, lastname, id } = props;
  return `${firstname} ${lastname}`;
}

export default User; 
