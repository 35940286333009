import { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'services/Utils';
import { reduxForm, change as reduxFormChange, reset as resetForm } from 'redux-form';
import * as moment from 'moment-timezone';
import { ModalUtils } from 'arachne-ui-components';
import { modal, form } from 'modules/AnalysisExecution/const';
import actions from 'actions/index';
import { buildRequestParams } from 'modules/AnalysisExecution/ducks/schedule';
import presenter from './presenter';
import selectors, { IDataSource } from './selectors';

interface ISchedulerState {
  analysisId: number;
  dataSources: Array<IDataSource>;
  initialValues: Object;
  isOpened: boolean;
  jobId: number;
  schedulerEnabled: boolean;
  shouldPickDays: boolean;
  selectedDataSourcesLength: number;
  timeZone: string;
  isOnce: boolean;
}
interface ISchedulerDispatch {
  closeModal: Function;
  changeEndCondition: Function;
  reset: Function;
  save: Function;
  update: Function;
  loadAnalysis: Function;
  loadJob: Function;
}
interface ISchedulerProps extends ISchedulerState, ISchedulerDispatch {

}
class Scheduler extends Component<ISchedulerProps, {}> {
  componentWillReceiveProps(props) {
    if (this.props.isOpened !== props.isOpened) {
      if (props.isOpened === true) {
        this.props.loadJob({ analysisId: this.props.analysisId });
      }
    }
  }

  render() {
    return presenter(this.props);
  }
}

function mapStateToProps(state: Object): ISchedulerState {
  const schedulerEnabled = get(state, 'form.scheduler.values.repeat', false);
  const analysisId = get(state, 'analysisExecution.analysis.data.result.id');
  const shouldPickDays = get(state, 'form.scheduler.values.repeatType') === 'WEEKLY';
  const isOnce = get(state, 'form.scheduler.values.repeatType') === 'ONCE';
  const isOpened = get(state, 'modal.scheduler.isOpened', false);
  const defaultDate = moment(get(state, 'modal.scheduler.data.defaultDate', Date.now()));
  const timeFormat = 'hh:mm a';
  const dateFormat = 'MM/DD/YYYY';
  const initialValues = {
    dataSources: [],
    repeat: false,
    repeatTime: defaultDate.format(timeFormat),
    // without formatting, form will continiously re-initialize
    repeatDate: defaultDate.format(dateFormat),
    repeatType: 'ONCE',
    repeatDay: {
      SUNDAY: false,
      MONDAY: false,
      TUESDAY: false,
      WEDNESDAY: false,
      THURSDAY: false,
      FRIDAY: false,
      SATURDAY: false,
    },
    repeatEnds: {
      never: true,
      count: false,
      date: false,
    },
    endCount: 1,
    endTime: defaultDate.format(timeFormat),
    // without formatting, form will continiously re-initialize
    endDate: defaultDate.format(dateFormat),
  };
  const savedSchedule = get(state, 'analysisExecution.schedule.data.result') || null;
  if (savedSchedule !== null) {
    initialValues.repeat = savedSchedule.enabled;
    const startDate = moment(savedSchedule.startDate);
    initialValues.repeatTime = startDate.format(timeFormat);
    initialValues.repeatDate = startDate.format(dateFormat);
    initialValues.repeatType = savedSchedule.frequency;
    savedSchedule.weekDays.forEach((day) => {
      initialValues.repeatDay[day] = true;
    });
    if (savedSchedule.recurringUntilDate) {
      initialValues.repeatEnds.never = false;
      initialValues.repeatEnds.date = true;
      const endDate = moment(savedSchedule.recurringUntilDate, 'x');
      initialValues.endTime = endDate.format(timeFormat);
      initialValues.endDate = endDate.format(dateFormat);
    } else if (savedSchedule.recurringTimes !== 0) {
      initialValues.repeatEnds.never = false;
      initialValues.repeatEnds.count = true;
      initialValues.endCount = savedSchedule.recurringTimes;
      // once
      if (savedSchedule.recurringTimes === 1) {
        initialValues.repeatType = 'ONCE';
      }
    }
    savedSchedule.dataSourceDTO.forEach((dataSource) => {
      initialValues.dataSources[dataSource.id] = true;
    }
    );
  }
  const selectedDataSourcesLength = get(state, 'form.scheduler.values.dataSources', []).filter(ds => !!ds).length;
  const jobId = get(savedSchedule, 'id');

  return {
    analysisId,
    dataSources: selectors.getDataSources(state),
    initialValues,
    isOpened,
    jobId,
    schedulerEnabled,
    shouldPickDays,
    selectedDataSourcesLength,
    timeZone: moment(initialValues.repeatDate, dateFormat).tz(moment.tz.guess()).format('z'),
    isOnce,
  };
}

const mapDispatchToProps: ISchedulerDispatch = {
  closeModal: () => ModalUtils.actions.toggle(modal.scheduler, false),
  changeEndCondition: val => reduxFormChange(form.scheduler, 'repeatEnds', val),
  reset: () => resetForm(form.scheduler),
  save: actions.analysisExecution.schedule.create,
  update: actions.analysisExecution.schedule.update,
  loadAnalysis: actions.analysisExecution.analysis.find,
  loadJob: actions.analysisExecution.schedule.find,
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    onEndConditionChange: (selectedCondition) => {
      const fieldValue = {
        never: false,
        count: false,
        date: false,
      };

      fieldValue[selectedCondition] = true;
      return dispatchProps.changeEndCondition(fieldValue);
    },
    doSubmit: (scheduleSettings) => {
      let dataSources = [];
      if (Array.isArray(scheduleSettings.dataSources)) {
        dataSources = Object.keys(scheduleSettings.dataSources).filter(key => !!scheduleSettings.dataSources[key])
          .map(id => ({ id }));
      }
      let weekDays = Object.keys(scheduleSettings.repeatDay);
      weekDays = weekDays.filter(day => scheduleSettings.repeatDay[day] === true);
      if (!weekDays.length) {
        weekDays.push(moment().format('dddd').toUpperCase());
      }
      const request = buildRequestParams({
        analysisId: stateProps.analysisId,
        schedulerEnabled: stateProps.schedulerEnabled,
        ...scheduleSettings,
        repeatDay: weekDays,
        dataSources,
        id: stateProps.jobId,
      });

      const promise = stateProps.jobId
        ? dispatchProps.update({ analysisId: stateProps.analysisId }, request)
        : dispatchProps.save({ analysisId: stateProps.analysisId }, request);
      promise.then(() => dispatchProps.reset())
        .then(() => dispatchProps.closeModal())
        .then(() => dispatchProps.loadAnalysis({ id: stateProps.analysisId }))
        .catch(() => {});

      return promise;
    },
    checkForChanges: (isFormDirty) => {
      if (isFormDirty) {
        if (confirm('There are unsaved changes. Do you want to discard them?')) {
          dispatchProps.reset();
          return true;
        }
        return false;
      }
      return true;
    },
  };
}

let ReduxScheduler = ModalUtils.connect({
  name: modal.scheduler,
})(Scheduler);

ReduxScheduler = reduxForm({
  form: form.scheduler,
  enableReinitialize: true,
})(ReduxScheduler);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ReduxScheduler);
