import { get } from 'services/Utils';
import CommunityBuilder from 'community/modules/DataCatalog/components/DataSource/ViewEdit/View/ModalConfirmDatasource/container';
import { modal } from 'modules/DataCatalog/const';
import ConnectedComponentBuilder from 'interfaces/ConnectedComponentBuilder';

class ModalConfirmDSInviteBuilder extends CommunityBuilder implements ConnectedComponentBuilder {

  mapStateToProps(state) {
    const approvedImmediately = get(state, `modal.${modal.confirmDatasource}.data.approvedImmediately`, false);
    
    const modalTitle = approvedImmediately ? 'Attach data source' : 'Invite data source';
    const modalText = approvedImmediately ? 'You attached' : 'Your invite has been sent to';

    return {
      ...super.mapStateToProps(state),
      modalTitle,
      modalText,
    };
  }

}

export default ModalConfirmDSInviteBuilder;
