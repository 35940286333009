import * as React from 'react';
import CommunityEdit from 'community/modules/DataCatalog/components/DataSource/ViewEdit/Edit/presenter';
import ReportAccessParams from './ReportAccessParams';

export default class Edit extends CommunityEdit {
  getAchillesSettings() {
    return [
      ...super.getAchillesSettings(),
      <ReportAccessParams />,
    ];
  }  
}
