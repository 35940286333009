import * as React from 'react';
import CommunityViewEditAnalysis from 'community/modules/AnalysisExecution/components/ViewEditAnalysis/presenter';
import ModalPackratBundle from './ModalPackratBundle';
import Scheduler from './Scheduler';

export default class ViewEditAnalysis extends CommunityViewEditAnalysis {
  getModals() {
    return [
      ...super.getModals(),
      <ModalPackratBundle />,
      <Scheduler />,
    ];
  }
}
