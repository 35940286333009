import * as communityConst from 'community/modules/StudyManager/const';
import keyMirror = require('keymirror');

const apiPaths = {
  ...communityConst.apiPaths,
  recentActivity: studyId => `/api/v1/study-management/studies/${studyId}/activities`,
  activitiesSubscription: () => `/user/topic/activities`,
};

const recentActivityTitles = {
  CREATING: 'created study',
  ADDING_CONTRIBUTOR: 'added participant',
  ADDING_DATA_SOURCE: 'added new datasource',
  CREATING_VIRTUAL_DATA_SOURCE: 'created new virtual datasource',
  ADDING_DATA_SOURCE_OWNER: 'invited datasource owner',
  ADDING_OWNER_TO_VIRTUAL_DATA_SOURCE: 'added owners to virtual datasource',
  CREATING_ANALYSIS: 'created analysis',
  DELETING_DATA_SOURCE: 'removed datasource',
  DELETING_FILE: 'removed document',
  DELETING_PARTICIPANT: 'deleted participant',
  INVITATION_ACCEPTED: 'is now collaborating',
  INVITATION_DECLINED: 'declined an invitation',
  UPDATING_END_DATE: 'changed end date to',
  UPDATING_OBJECTIVE: 'updated study objective to',
  UPDATING_PRIVACY: 'changed privacy settings to',
  UPDATING_START_DATE: 'changed start date to',
  UPDATING_STATUS: 'changed study status to',
  UPDATING_TYPE: 'changed study type to',
  UPLOADING_FILE: 'added document',

  DELETING_ANALYSIS: 'deleted analysis',
  UPDATING_ANALYSIS: 'updated analysis',
  UPDATING_ANALYSIS_TITLE: 'renamed analysis to',
  UPDATING_ANALYSIS_DESCRIPTION: 'changed analysis description',
  LOCKING_ANALYSIS: 'locked/unlocked analysis',
  SENDING_UNLOCK_ANALYSIS_REQUEST: 'requested unlock for analysis',
  PROCESSING_UNLOCK_ANALYSIS_REQUEST: 'processed unlock request for analysis',

  UPLOADING_CODE_FILE: 'updloaded code file to analysis',
  DELETING_CODE_FILE: 'removed code file from analysis',
  UPDATING_CODE_FILE: 'updated code file for analysis',

  CREATING_SUBMISSION: 'submitted files for analysis',
  CHANGING_STATUS_SUBMISSION: 'changed submission status',
  UPLOADING_SUBMISSION_RESULT: 'added result files to submission',
  UPDATING_TITLE: 'renamed study to',
};

const recentActivityTypes = keyMirror({
  CREATING: null,
  ADDING_CONTRIBUTOR: null,
  ADDING_DATA_SOURCE: null,
  ADDING_DATA_SOURCE_OWNER: null,
  CREATING_ANALYSIS: null,
  DELETING_DATA_SOURCE: null,
  DELETING_FILE: null,
  DELETING_PARTICIPANT: null,
  INVITATION_ACCEPTED: null,
  INVITATION_DECLINED: null,
  UPDATING_END_DATE: null,
  UPDATING_OBJECTIVE: null,
  UPDATING_PRIVACY: null,
  UPDATING_START_DATE: null,
  UPDATING_STATUS: null,
  UPDATING_TYPE: null,
  UPLOADING_FILE: null,

  DELETING_ANALYSIS: null,
  UPDATING_ANALYSIS: null,
  UPDATING_ANALYSIS_TITLE: null,
  UPDATING_ANALYSIS_DESCRIPTION: null,

  UPLOADING_CODE_FILE: null,
  DELETING_CODE_FILE: null,
  UPDATING_CODE_FILE: null,

  CREATING_SUBMISSION: null,
  CHANGING_STATUS_SUBMISSION: null,
  UPLOADING_SUBMISSION_RESULT: null,
  UPDATING_TITLE: null,
});

const affectedEntityTypes = keyMirror({
  ANALYSIS: null,
  BOOLEAN: null,
  CODE_FILE: null,
  DATA_SOURCE: null,
  DATE: null,
  FILE: null,
  NONE: null,
  RESULT_FILE: null,
  STRING: null,
  SUBMISSION: null,
  SUBMISSION_STATUS: null,
  USER: null,
  // used for displaying CREATING_ANALYSIS activity for deleted analysis
  ANALYSIS_TITLE: null,
});

export * from 'community/modules/StudyManager/const';
export {
  apiPaths,
  recentActivityTitles,
  recentActivityTypes,
  affectedEntityTypes,
};
