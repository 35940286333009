import Duck from 'services/Duck';
import { action as actionName } from 'services/CrudActionNameBuilder';
import ReducerFactory from 'services/ReducerFactory';

const actionCoreName = 'SM_STUDY_RECENT_ACTIVITY_SETTINGS';

function updatePageSize(pageSize) {
  return {
    type: actionName(actionCoreName).query().done().toString(),
    payload: {
      pageSize,
    },
  };
}

function changePageSize(pageSize) {
  return dispatch => dispatch(updatePageSize(pageSize));
}

const reducer = new ReducerFactory()
  .setActionHandler(
    actionName(actionCoreName).query().done().toString(),
    (state, action) => ({
      ...state,
      data: action.payload,
    })
  )
  .build();

export default {
  actions: {
    changePageSize,
  },
  reducer,
};
