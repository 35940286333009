import * as React from 'react';
import { Field } from 'redux-form';
import BEMHelper from 'services/BemHelper';
import {
  Button,
  Panel,
  FormSelect,
  Fieldset,
} from 'arachne-ui-components';

import './style.scss';

function ReportAccessParams(props) {
  const classes = BEMHelper('report-access-form');

  const {
    accessOptions,
    reportsPermissions,
		doSubmit,
  	// redux-form props
  	error,
    handleSubmit,
    submitting,
  } = props;

  return (
    <Panel title="Access to reports" {...classes()}>
      <form
        onSubmit={handleSubmit(doSubmit)}
      >
        {reportsPermissions && reportsPermissions.map((perm) => {
          return <div {...classes({ element: 'row', extra: 'row' })}>
            <div {...classes({ element: 'label', extra: 'col-xs-5' })}>
              {perm.report.name}
            </div>
            <div {...classes({ element: 'input', extra: 'col-xs-7' })}>
              <Field
                component={Fieldset}
                name={perm.report.id}
                InputComponent={{
                  component: FormSelect,
                  props: {
                    options: accessOptions,
                    mods: ['bordered'],
                  }
                }}
              />
            </div>
          </div>
        }
        )}
        <div {...classes({ element: 'row', modifiers: 'actions' })}>
          <Button
            {...classes('btn')}
            type='submit'
            label={submitting ? 'Saving...' : 'Save'}
            mods={['success', 'rounded']}
          />
        </div>
      </form>
    </Panel>
  );
}

export default ReportAccessParams;
