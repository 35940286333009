import * as React from 'react';
import { PropTypes } from 'react';
import BEMHelper from 'services/BemHelper';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { BadgedIcon, Panel, LoadingPanel } from 'arachne-ui-components';
import ActivityItem from 'components/ActivityItem';

import './style.scss';

function NotificationList(props) {
  const classes = BEMHelper('notify-dropdown');
  const {
    notifications,
    unreadCount,
    markAllAsRead,
  } = props;

  return (
    <Dropdown {...classes()} onHide={markAllAsRead}>
      <DropdownTrigger {...classes('icon')}>
        <BadgedIcon count={unreadCount} icon="notifications" />
      </DropdownTrigger>
      <DropdownContent>
        <Panel title="Notifications" mods="black-header" {...classes('content')}>
          {!notifications.length &&
            <span {...classes('empty-state')}>No notifications</span>
          }
          <ul {...classes('list')}>{notifications.map((item, key) => <ActivityItem
              isFullTitle={true}
              key={key}
              {...item}
            />)}
          </ul>
          <LoadingPanel active={props.isLoading} />
        </Panel>
      </DropdownContent>
    </Dropdown>
  );
}

export default NotificationList;
