import * as React from 'react';
import communityPortal from 'community/modules/Portal';
import NotificationList from './NotificationListLoader';

if(__APP_TYPE_CENTRAL__) {
  const menuItems = communityPortal.menuItems();
  menuItems.splice(menuItems.length - 1, 0, <NotificationList />)
  communityPortal.menuItems = () => menuItems;
}

export default communityPortal;
