import { Component } from 'react';
import CommunityContainer, {
  StatefulEdit as CommunityStatefulEdit,
} from 'community/modules/DataCatalog/components/DataSource/ViewEdit/Edit/container';
import actions from 'actions';

interface Props {
  dataSourceId: number,
  loadDataSourceBusiness: Function;
  loadDataSourceMetadataAttrs: Function;
}

class StatefulEdit extends CommunityStatefulEdit implements Component<Props, {}> {

  componentWillReceiveProps(nextProps) {
    super.componentWillReceiveProps(nextProps);
    if (this.props.dataSourceId !== nextProps.dataSourceId) {
      this.props.loadDataSourceMetadataAttrs();
    }
  }

}

export default class Edit extends CommunityContainer {
  getComponent() {
    return StatefulEdit;
  }
  
  getMapDispatchToProps() {
    return {
      ...super.getMapDispatchToProps(),
      loadReportAccessParams: actions.dataCatalog.reportsPermissions.query,
      loadDataSourceMetadataAttrs: actions.dataCatalog.dataSourceMetadataAttrs.query,
    };
  }

  getFetchers({ params, state, dispatch }) {
    const datasourceId = params.dataSourceId;
    
    return {
      ...super.getFetchers({ params, state, dispatch }),
      loadReportAccessParams: actions.dataCatalog.reportsPermissions.query.bind(null, { datasourceId }),
      loadDataSourceMetadataAttrs: actions.dataCatalog.dataSourceMetadataAttrs.query,
    };
  }
}

export {
  StatefulEdit,
};
