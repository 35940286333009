/*
 *
 * Copyright 2018 Odysseus Data Services, inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Company: Odysseus Data Services, Inc.
 * Product Owner/Architecture: Gregory Klebanov
 * Authors: Pavel Grafkin, Alexander Saltykov, Vitaly Koulakov, Anton Gackovka, Alexandr Ryabokon, Mikhail Mironov
 * Created: January 26, 2017
 *
 */

import React from 'react';
import { Route } from 'react-router';
import ListDataSources from './components/DataSource/List';
import ViewDataSource from './components/DataSource/ViewEdit/View';
import EditDataSource from './components/DataSource/ViewEdit/Edit';
import MyDatasources from './components/DataSource/MyDatasources';
import ViewEditDatanode from './components/DataNode/ViewEdit';

function Routes() {
  return [
    <Route path="data-sources" component={ListDataSources} />,
    <Route path="my-data-sources" component={MyDatasources} />,
    <Route path="data-sources/:dataSourceId" component={ViewDataSource} />,
    <Route path="data-sources/:dataSourceId/profile" component={ViewDataSource} params={{ isProfileSelected: true }} />,
    <Route path="data-sources/:dataSourceId/edit" component={EditDataSource} />,
    <Route path="data-nodes/:datanodeId" component={ViewEditDatanode} />,
  ];
}

export default Routes;
