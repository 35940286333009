import * as React from 'react';
import { Component } from 'react';
import ducks from 'modules/DataCatalog/ducks';
import { get } from 'services/Utils';
import DataCatalogViewBuilder, { StatefulView } from 'community/modules/DataCatalog/components/DataSource/ViewEdit/View/container';
import ConnectedComponentBuilder from 'interfaces/ConnectedComponentBuilder';

interface IStatefulViewProps {
  dataSourceUuid: string;
  loadDataSource: Function;
  loadReportsList: Function;
  loadCharacterization: Function;
}

interface IEnterpriseStatefulViewDispatchProps {
	loadMetadataAttrs: Function;
}

interface IEnterpriseStatefulViewProps extends IStatefulViewProps, IEnterpriseStatefulViewDispatchProps {};

class EnterpriseStatefulView
  extends StatefulView 
  implements Component<IEnterpriseStatefulViewProps, any> {
  
  componentWillReceiveProps(nextProps) {
    super.componentWillReceiveProps(nextProps);
    if (this.props.dataSourceUuid !== nextProps.dataSourceUuid) {
      this.props.loadMetadataAttrs();
    }
  }
}

class EnterpriseDataCatalogViewBuilder extends DataCatalogViewBuilder implements ConnectedComponentBuilder {

  getComponent() {
    return EnterpriseStatefulView;
  }

  getMapDispatchToProps() {
    const communityMappings = super.getMapDispatchToProps();

    return {
      ...communityMappings,
      loadMetadataAttrs: ducks.actions.dataSourceMetadataAttrs.query,
    };
  }

  getFetchers({ params, state, dispatch }) {
    const communityFetchers = super.getFetchers({ params, state, dispatch });

    return {
      ...communityFetchers,
      loadMetadataAttrs: ducks.actions.dataSourceMetadataAttrs.query,
    };
  }
  
}

export default EnterpriseDataCatalogViewBuilder;
export {
  EnterpriseStatefulView as StatefulView,
};
