/*
 *
 * Copyright 2018 Odysseus Data Services, inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Company: Odysseus Data Services, Inc.
 * Product Owner/Architecture: Gregory Klebanov
 * Authors: Pavel Grafkin, Alexander Saltykov, Vitaly Koulakov, Anton Gackovka, Alexandr Ryabokon, Mikhail Mironov
 * Created: August 25, 2017
 *
 */

import React from 'react';
import BEMHelper from 'services/BemHelper';
import { Button } from 'arachne-ui-components';

require('./style.scss');

function ToolbarActions({
    canUnpublish,
    unpublish,
    canEdit,
    dataSourceId,
    editUrl,
    canDelete,
    remove,
  }) {
  const classes = new BEMHelper('data-source-toolbar-actions');

  return (
    <div  {...classes()}>
      {canUnpublish &&
        <Button onClick={unpublish} mods={['rounded', 'cancel']}>
          Unpublish
        </Button>
      }
      {canEdit &&
        <Button {...classes('action')} link={editUrl}>
          <i {...classes('action-ico')}>settings</i>
        </Button>
      }
      {canDelete &&
        <Button onClick={remove} {...classes('delete-button')}>
          delete
        </Button>
      }
    </div>
  );
}

export default ToolbarActions;
