import keyMirror = require('keymirror');
import {
  modal as communityModal,
  apiPaths as communityApiPaths,
  form as communityForm,
} from 'community/modules/AnalysisExecution/const';

const modal = {
  ...communityModal,
  ...keyMirror({
    packratBundle: null,
    scheduler: null,
  }),
};

const form = {
  ...communityForm,
  ...keyMirror({
    scheduler: null,
    newAnalysisFile: null,
  }),
};

const apiPaths = {
  ...communityApiPaths,
  schedule: ({ analysisId }) => `/api/v1/analysis-management/analyses/${analysisId}/jobs`,
  createWrapperForSelectedEntryPoint: ({ analysisId, fileUuid }) =>
    `/api/v1/analysis-management/analyses/${analysisId}/code-files/${fileUuid}/create-packrat-wrapper`,
  updateAnalysiscode: ({ analysisId, analysisCodeId, isExecutable }) =>
    `/api/v1/analysis-management/analyses/${analysisId}/fileIsExecutable/${analysisCodeId}?isExecutable=${isExecutable}`,
};

export * from 'community/modules/AnalysisExecution/const';
export {
  modal,
  apiPaths,
  form,
};
