import * as React from 'react';
import BEMHelper from 'services/BemHelper';
import { paths as studyPaths } from 'modules/StudyManager/const';
import { paths as analysisPaths } from 'modules/AnalysisExecution/const';
import FileInfo from 'components/FileInfo';

import './style.scss';

interface ILink {
  to?: string;
  target?: string;
};

function Document(props) {
  const {
    analysisId,
    docType,
    label,
    studyId,
    submissionId,
    type,
    uuid
  } = props;
  const classes = BEMHelper('activity-document');
  let link: ILink = {};
  switch (type) {
    case 'STUDY':
      link = {
        to: studyPaths.studyFile({ studyId, fileId: uuid }),
        target: '_self',
      };
      break;
    case 'ANALYSIS':
      link = {
        to: analysisPaths.analysisCode({ analysisId, codeFileId: uuid }),
      };
      break;
    case 'SUBMISSION':
      link = {
        to: analysisPaths.submissionResultFile({ submissionId, fileId: uuid }),
      };
      break;
  }
  const Info = FileInfo;

  return (
    <div {...classes()}>
      <Info
        docType={docType}
        label={label}
        link={link.to}
        linkTarget={link.target} />
    </div>
  );
}

export function DocumentText(props) {
  const {
    label,
  } = props;
  const Info = FileInfo;

  return label;
}

export default Document;
  