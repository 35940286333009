import * as React from 'react';
import {
  activityTypes,
  apiPaths,
  participantRoles,
  paths,
  submissionStatusTitles
} from 'const/activity';
import fileConverter from 'components/FileInfo/converter';

import Document, { DocumentText } from '../Document/presenter';
import User, { UserText } from '../User/presenter';
import Privacy, { PrivacyText } from '../Privacy/presenter';
import Date, { DateText } from '../Date/presenter';
import LabelFiles, { LabelFilesText } from '../LabelFiles/presenter';
import Text from '../Text/presenter';

interface IDescriptionParams {
  type: string;
  entities: any;
}

function Description(params: IDescriptionParams) {
  const { type, entities } = params;
  switch (type) {
    case activityTypes.DELETING_FILE:
    case activityTypes.UPLOADING_FILE:
      return entities.STRING
        ? <Text {...entities.STRING} />
        : <Document
            {...fileConverter(entities.FILE)}
            studyId={entities.FILE.studyId}
            type='STUDY'
          />;

    case activityTypes.DELETING_CODE_FILE:
    case activityTypes.UPDATING_CODE_FILE:
    case activityTypes.UPLOADING_CODE_FILE:
      return entities.STRING
        ? <Text {...entities.STRING} />
        : <Document
            {...fileConverter(entities.CODE_FILE)}
            analysisId={entities.CODE_FILE.analysisId}
            type='ANALYSIS'
          />;

    case activityTypes.UPLOADING_SUBMISSION_RESULT:
      return entities.STRING
        ? <LabelFiles {...entities.STRING} />
        : <Document
            {...fileConverter(entities.RESULT_FILE)}
            submissionId={entities.RESULT_FILE.submissionId}
            type='SUBMISSION'
            label={entities.RESULT_FILE.name}
          />;
    case activityTypes.DELETE_SUBMISSION_RESULT:
      return <Text {...entities.STRING} />;

    case activityTypes.DELETING_PARTICIPANT:
    case activityTypes.ADDING_CONTRIBUTOR:
    case activityTypes.CHANGING_CONTRIBUTOR_ROLE:
    case activityTypes.ADDING_DATA_SOURCE_OWNER:
    case activityTypes.ADDING_OWNER_TO_VIRTUAL_DATA_SOURCE:
      return <User {...entities.USER} />;

    case activityTypes.UPDATING_START_DATE:
    case activityTypes.UPDATING_END_DATE:
      return <Date {...entities.DATE} />;

    case activityTypes.UPDATING_PRIVACY:
      return <Privacy isPrivate={entities.BOOLEAN.value} />;

    case activityTypes.UPDATING_OBJECTIVE:
    case activityTypes.UPDATING_STATUS:
    case activityTypes.UPDATING_TYPE:
    case activityTypes.UPDATING_TITLE:
    case activityTypes.UPDATING_ANALYSIS_TITLE:
    case activityTypes.UPDATING_ANALYSIS_TYPE:
      return <Text {...entities.STRING} />;

    default: return null;
  }
}

export function DescriptionText(params: IDescriptionParams) {
  const { type, entities } = params;
  switch (type) {
    case activityTypes.DELETING_FILE:
    case activityTypes.UPLOADING_FILE:
      return entities.STRING
        ? entities.STRING
        : DocumentText({
            ...fileConverter(entities.FILE),
            studyId: entities.FILE.studyId,
            type: 'STUDY',
        });

    case activityTypes.DELETING_CODE_FILE:
    case activityTypes.UPDATING_CODE_FILE:
    case activityTypes.UPLOADING_CODE_FILE:
      return entities.STRING
        ? entities.STRING
        : DocumentText({
            ...fileConverter(entities.CODE_FILE),
            analysisId: entities.CODE_FILE.analysisId,
            type: 'ANALYSIS',
        });

    case activityTypes.UPLOADING_SUBMISSION_RESULT:
      return entities.STRING
        ? LabelFilesText(entities.STRING)
        : DocumentText({
            ...fileConverter(entities.RESULT_FILE),
            submissionId: entities.RESULT_FILE.submissionId,
            type: 'SUBMISSION',
            label: entities.RESULT_FILE.name,
          });
    case activityTypes.DELETE_SUBMISSION_RESULT:
      return entities.STRING;

    case activityTypes.DELETING_PARTICIPANT:
    case activityTypes.ADDING_CONTRIBUTOR:
    case activityTypes.CHANGING_CONTRIBUTOR_ROLE:
    case activityTypes.ADDING_DATA_SOURCE_OWNER:
    case activityTypes.ADDING_OWNER_TO_VIRTUAL_DATA_SOURCE:
      return UserText(entities.USER);

    case activityTypes.UPDATING_START_DATE:
    case activityTypes.UPDATING_END_DATE:
      return DateText(entities.DATE);

    case activityTypes.UPDATING_PRIVACY:
      return PrivacyText({
        isPrivate: entities.BOOLEAN.value
      });

    case activityTypes.UPDATING_OBJECTIVE:
    case activityTypes.UPDATING_STATUS:
    case activityTypes.UPDATING_TYPE:
    case activityTypes.UPDATING_TITLE:
    case activityTypes.UPDATING_ANALYSIS_TITLE:
    case activityTypes.UPDATING_ANALYSIS_TYPE:
      return entities.STRING;

    default: return '';
  }
}

export default Description;
