import * as React from 'react';
import { Component, PropTypes } from 'react';
import {
  Button,
  RadioButton,
} from 'arachne-ui-components';
import BEMHelper from 'services/BemHelper';
import { CodeItem, ActionsLine } from 'community/modules/AnalysisExecution/components/ViewEditAnalysis/ListCode/presenter';
import CommunityListCode from 'community/modules/AnalysisExecution/components/ViewEditAnalysis/ListCode/presenter';
import { submissionFileUploadModes } from 'modules/AnalysisExecution/components/ViewEditAnalysis/ModalCreateCode/presenter';

import './style.scss';

export class EnterpriseActionsLine extends ActionsLine {
  private isScheduled: boolean;
  private schedulerClasses: Function;
  private openScheduler: Function;
  private canSubmit: boolean;
  private isExecutableSelected: any;

  constructor() {
    super();
    this.schedulerClasses = BEMHelper('analysis-scheduler-button');
  }

  getComponents() {
    const components = super.getComponents(false);
    const isSubmittable = this.canSubmit && this.isExecutableSelected;
    const schedule = (
      <Button
        {...this.schedulerClasses({ modifiers: { scheduled: this.isScheduled } })}
        mods={['submit', 'rounded']}
        onClick={this.openScheduler}
        disabled={!isSubmittable}>
        <span {...this.schedulerClasses('label')}>
            { this.isScheduled ? 'Scheduled' : 'Schedule' }
          </span>
      </Button>
    );
    const create = (
      <Button
        {...this.addClasses({ modifiers: { disabled: !this.canAddFiles } })}
        onClick={() => this.openCreateCodeModal(submissionFileUploadModes.CREATE)}
        disabled={!this.canAddFiles}
      >
        <span {...this.addClasses('ico')}>add</span>
        <span {...this.addClasses('label')}>New</span>
      </Button>
    );

    if (isSubmittable) {
      components.schedule = schedule;
    } else {
      components.submit = (<div {...this.tooltipClass()}
        data-tootik-conf="left"
        aria-label="Please, select entry point for your submission"
      >
        {React.cloneElement(components.submit, { disabled: !isSubmittable })}
      </div>);
      components.schedule = (<div {...this.tooltipClass({ extra: this.schedulerClasses().className })}
        data-tootik-conf="left"
        aria-label="Please, select entry point for your submission"
      >
        {schedule}
      </div>);
    }

    // ensure the right order
    return {
      add: components.add,
      create,
      importFromAchilles: components.importFromAchilles,
      schedule: components.schedule,
      submit: components.submit,
    };
  }

  render() {
    this.isScheduled = this.props.isScheduled;
    this.openScheduler = this.props.openScheduler;
    this.canSubmit = this.props.canSubmit;
    this.isExecutableSelected = this.props.isExecutableSelected;

    return super.render();
  }
}

export class EnterpriseCodeItem extends CodeItem {
  getFileDescription() {
    let subtitle = null;
    if (this.code.isPackrat) {
      subtitle = <span {...this.classes('entry-point')}>{this.code.entryPoint}</span>;
    }

    return subtitle;
  }

  getWrapperClassName() {
    return this.classes({ modifiers: { packrat: this.code.isPackrat && this.code.entryPoint } });
  }

  getComponent() {
    return [<div
      {...this.classes({
        element: 'tooltip',
        extra: this.code.selectable ? 'ac-tooltip' : '',
      })}
      aria-label='Main entry point for submission'
      data-tootik-conf='right'
      >
        <RadioButton
          {...this.classes('radiobutton')}
          isChecked={this.code.isExecutable}
          onChange={() => {
            if (this.code.isPackrat) {
              return this.props.selectExecutable(this.code.uuid, this.code.label, this.code.entryPoint);
            }
            return this.props.toggleExecutable(this.code.uuid, !this.code.isExecutable);
          }}
          isDisabled={!this.code.selectable || !this.isEditable || this.isLocked}
        />
      </div>,
      ...super.getComponent(),
    ];
  }
}

export default class ListCode extends CommunityListCode {
  private isScheduled: boolean;
  private schedulerClasses: Function;
  private openScheduler: Function;
  canSubmit: boolean;

  getCodeItems() {
    return this.codeList.map((code, key) =>
      <EnterpriseCodeItem
        code={code}
        isEditable={this.isEditable}
        reimportCode={this.reimportCode}
        removeCode={this.removeCode}
        toggleExecutable={this.props.toggleExecutable}
        canBeReimported={this.canAddFiles && code.removable && !this.isLocked}
        key={key}
        isLocked={this.isLocked}
        removable={code.removable}
        selectExecutable={this.props.selectExecutable}
      />
    );
  }

  getActions() {
    return (<EnterpriseActionsLine
      canSubmit={this.canSubmit}
      canAddFiles={!this.isLocked}
      isScheduled={this.isScheduled}
      openCreateCodeModal={this.openCreateCodeModal}
      openSubmitModal={this.openSubmitModal}
      isExecutableSelected={this.isExecutableSelected}
      openScheduler={() => this.openScheduler({
        defaultDate: Date.now(),
      })}
    />);
  }

  render() {
    this.isScheduled = this.props.isScheduled;
    this.openScheduler = this.props.openScheduler;
    this.canSubmit = this.props.codeList
      ? this.props.codeList.filter((f) => f.isExecutable).length
      : false;

    return super.render();
  }
}
