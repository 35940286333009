import Duck from 'community/services/Duck';
import { apiPaths } from 'modules/Admin/const';

const actionCoreName = 'AD_SOLR_REINDEX';

const reindexDuck = new Duck({
  name: actionCoreName,
  urlBuilder: apiPaths.reindexSolr,
});

const actions = reindexDuck.actions;
const reducer = reindexDuck.reducer;

export default {
  actions,
  reducer,
};