import Duck from 'services/Duck';
import { apiPaths } from 'modules/AnalysisExecution/const';

const codeBundle = new Duck({
  name: 'AE_BUNDLE_CODE',
  urlBuilder: apiPaths.analysisCodeBundle,
});

const codeWrapper = new Duck({
  name: 'AE_BINDLE_ENTRY_POINT',
  urlBuilder: apiPaths.createWrapperForSelectedEntryPoint,
});

const executable = new Duck({
  name: 'AE_EXECUTABLE',
  urlBuilder: apiPaths.updateAnalysiscode,
});

export default {
  actions: {
    ...codeBundle.actions,
    codeWrapper: codeWrapper.actions,
    selectExecutable: executable.actions.update,
  },
  reducer: codeBundle.reducer,
};
