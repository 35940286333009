import actions from 'actions';
import { get } from 'services/Utils';
import { ContainerBuilder } from 'services/Utils';
import { form, modal, paths } from 'modules/AnalysisExecution/const';
import { reset as resetForm } from 'redux-form';
import { push } from 'react-router-redux';
import NewAnalysisFile from './presenter';

export default class NewAnalysisFileBuilder extends ContainerBuilder {

  getComponent() {
    return NewAnalysisFile;
  }

  mapStateToProps(state) {
    const analysisData = get(state, 'analysisExecution.analysis.data.result');

    return {
      analysisId: get(analysisData, 'id'),
    };
  }

  getMapDispatchToProps() {
    return {
      createFile: actions.analysisExecution.analysisCode.create,
      redirect: push,
    };
  }

  getFormParams() {
    return {
      form: form.newAnalysisFile,
    }
  }

  mergeProps(stateProps, dispatchProps, ownProps) {
    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      doSubmit(data) {
        const promise = dispatchProps.createFile({ analysisId: stateProps.analysisId }, data);

        promise.then(res => dispatchProps.redirect(paths.analysisCode({
          analysisId: stateProps.analysisId,
          codeFileId: res.uuid,
        })));

        return promise;
      }
    };
  }
}
