// @ts-check
import keyMirror = require('keymirror');
import { types as fieldTypes } from 'const/modelAttributes';
import {
  staticAttrList as communityStaticAttrList,
  immutableAttributes as communityImmutableAttributes,
  attributeNames,
  accessType,
  fieldHints as communityFieldHints,
  modelTypes,
  executionPolicy as communityExecutionPolicy,
} from 'community/const/dataSource';

const executionPolicy = {
  ...communityExecutionPolicy,
  ...keyMirror({
    SUPERVISED: null,
    UNSUPERVISED: null,
  }),
};

/**
 * @type {
    Array<{
      label: string,
      name: string,
      type: string,
      getValue?: Function,
      faceted: boolean,
      showInList: boolean,
      options: Array<{label: string, value: string | number}>,
      min?: number,
      max?: number,
      order?: number,
      calculated?: boolean
    }>
  }
*/
const staticAttrList = [
  ...communityStaticAttrList,
  {
    label: 'Execution Policy',
    name: attributeNames.executionPolicy,
    type: fieldTypes.enum,
    faceted: true,
    options: [
      {
        label: 'Supervised',
        value: executionPolicy.SUPERVISED,
      },
      {
        label: 'Unsupervised',
        value: executionPolicy.UNSUPERVISED,
      },
      {
        label: 'Manual',
        value: executionPolicy.MANUAL,
        disabled: true,
      },
    ],
    showInList: true,
    isRequired: true,
    order: 1
  },
];

const executionPolicyField = 'executionPolicy';

const immutableAttributes = [
  ...communityImmutableAttributes,
  attributeNames.modelType,
  attributeNames.cdmVersion,
];

const fieldHints = {
  ...communityFieldHints,
  [attributeNames.modelType]: `
    Model type has been detected automatically. To update it change connection details of the data source at data node side.
    Available model types: ${modelTypes.map(mt => mt.label).join(', ')}
  `,
};

export * from 'community/const/dataSource';
export {
  staticAttrList,
  immutableAttributes,
  fieldHints,
  attributeNames,
  accessType,
  executionPolicy,
  executionPolicyField,
};
