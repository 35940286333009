import { apiPaths } from 'modules/AnalysisExecution/const';
import Duck from 'services/Duck';
import * as moment from 'moment';
import { SubmissionError } from 'redux-form';

function checkTime(time, type) {
  if (!moment(time, 'hh:mm a', true).isValid()) {
    throw new SubmissionError({
      _error: `You should enter a valid ${type} time`,
    });
  }
}

export const buildRequestParams = ({
  schedulerEnabled,
  repeatTime,
  repeatDate,
  repeatType,
  repeatDay,
  repeatEnds,
  endCount,
  endTime,
  endDate,
  dataSources,
  id,
}) => {
  checkTime(repeatTime, 'start');
  checkTime(endTime, 'end');
  const startTime = repeatTime.split(':');
  const recurringUntilDate = endTime.split(':');
  const isStartTimePm = moment(repeatTime, 'hh:mm a', true).format('a') === 'pm';
  const isEndTimePm = moment(endTime, 'hh:mm a', true).format('a') === 'pm';
  let startHours = isStartTimePm
    ? parseInt(startTime[0], 0) + 12
    : parseInt(startTime[0], 0);
  let endHours = isEndTimePm
    ? parseInt(recurringUntilDate[0], 0) + 12
    : parseInt(recurringUntilDate[0], 0);
  if (startHours % 12 === 0) {
    // 12:01 pm -> 12:01 in 24-hours, though valid in terms of momentjs
    // 12:01 am -> 00:01
    startHours -= 12;
  }
  if (endHours % 12 === 0) {
    // 12:01 pm -> 12:01 in 24-hours, though valid in terms of momentjs
    // 12:01 am -> 00:01
    endHours -= 12;
  }

  const requestParams = {
    dataSourceDTO: dataSources,
    enabled: schedulerEnabled,
    frequency: repeatType,
    recurringTimes: null,
    recurringUntilDate: null,
    startDate: moment(repeatDate, 'MM-DD-YYYY').set({
      hours: startHours,
      minutes: startTime[1].split(' ')[0],
    }).format('x'),
    weekDays: repeatDay,
    id,
  };
  if (repeatEnds.date === true) {
    requestParams.recurringUntilDate = moment(endDate, 'MM-DD-YYYY').set({
      hours: endHours,
      minutes: recurringUntilDate[1].split(' ')[0],
    }).format('x');
  } else if (repeatEnds.count === true) {
    requestParams.recurringTimes = endCount;
  }

  if (repeatType === 'ONCE') {
    requestParams.frequency = 'DAILY';
    requestParams.recurringTimes = 1;
  }

  return requestParams;
}

const schedule = new Duck({
  name: 'AE_SCHEDULE',
  urlBuilder: apiPaths.schedule,
});

export default {
  actions: schedule.actions,
  reducer: schedule.reducer,
};
