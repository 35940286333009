import keyMirror = require('keymirror');
import {
  apiPaths as communityApiPaths,
  forms as communityForm,
} from 'community/modules/DataCatalog/const';
import { accessType as dsAccessType } from 'const/dataSource';

const apiPaths = {
  ...communityApiPaths,
  reportsPermissions: ({ datasourceId, id }) => `/api/v1/achilles/datanode/datasource/${datasourceId}/permissions${id ? '/'+id : ''}`,
};

const form = {
  ...communityForm,
  ...keyMirror({
    reportAccessParams: null,
  }),
}

const grantTypes = keyMirror({
  APPROVED: null,
  REJECTED: null,
  DISABLED: null,
});

const getReportAccessTypes = (visibilityLevel) => {
  switch (visibilityLevel) {
    case dsAccessType.PUBLIC:
      return [
        {
          label: 'Visible to all',
          value: grantTypes.APPROVED,
        },
        {
          label: 'Always hidden',
          value: grantTypes.DISABLED,
        },
      ];
    case dsAccessType.RESTRICTED:
      return [
        {
          label: 'Visible to all',
          value: grantTypes.APPROVED,
        },
        {
          label: 'Visible to approved contributors',
          value: grantTypes.REJECTED,
        },
        {
          label: 'Always hidden',
          value: grantTypes.DISABLED,
        }
      ];
  }

  return [];
};

export * from 'community/modules/DataCatalog/const';
export {
  apiPaths,
  form,
  getReportAccessTypes,
  grantTypes,
};
