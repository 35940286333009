import * as React from 'react';
import { formatNumberWithLabel } from 'services/Utils';

function LabelFiles(props) {
  const { value } = props;
  return <span>{formatNumberWithLabel({ label: 'file', value })}</span>
}

export function LabelFilesText(props) {
  const { value } = props;

  return formatNumberWithLabel({ label: 'file', value });
}

export default LabelFiles;
