import communityDucks from 'community/modules/Admin/ducks';
import attributes from './attributes';
import options from './options';
import reindex from './reindex';
import tenants from './tenants';
import tenantDataSources from './tenantDataSources';
import tenantDataSourcesOptions from './tenantDataSourcesOptions';
import tenantAtlases from './tenantAtlases';
import tenantAtlasesOptions from './tenantAtlasesOptions';
import tenantUsers from './tenantUsers';
import tenantUsersOptions from './tenantUsersOptions';

const actions = {
  ...communityDucks.actions,
  metadataAttributes: attributes.actions,
  metadataOptions: options.actions,
  reindexSolr: reindex.actions,
  tenants: tenants.actions,
  tenantDataSources: tenantDataSources.actions,
  tenantDataSourcesOptions: tenantDataSourcesOptions.actions,
  tenantAtlases: tenantAtlases.actions,
  tenantAtlasesOptions: tenantAtlasesOptions.actions,
  tenantUsers: tenantUsers.actions,
  tenantUsersOptions: tenantUsersOptions.actions,
};

const communityReducer = communityDucks.reducer;

const enterpriseReducer = {
  ...communityReducer,
  metadataAttributes: attributes.reducer,
  metadataOptions: options.reducer,
  reindexSolr: reindex.reducer,
  tenants: tenants.reducer,
  tenantDataSources: tenantDataSources.reducer,
  tenantDataSourcesOptions: tenantDataSourcesOptions.reducer,
  tenantAtlases: tenantAtlases.reducer,
  tenantAtlasesOptions: tenantAtlasesOptions.reducer,
  tenantUsers: tenantUsers.reducer,
  tenantUsersOptions: tenantUsersOptions.reducer,
};

export default {
  actions,
  reducer: enterpriseReducer,
}