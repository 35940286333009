import communityDucks from 'community/modules/AnalysisExecution/ducks';
import schedule from './schedule';
import codeBundle from './analysisBundleCode';

export default {
  actions: {
    ...communityDucks.actions,
    schedule: schedule.actions,
    codeBundle: codeBundle.actions,
  },
  reducer: {
    ...communityDucks.reducer,
    schedule: schedule.reducer,
    codeBundle: codeBundle.reducer,
  },
};
