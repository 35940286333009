/*
 *
 * Copyright 2018 Odysseus Data Services, inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Company: Odysseus Data Services, Inc.
 * Product Owner/Architecture: Gregory Klebanov
 * Authors: Pavel Grafkin, Alexander Saltykov, Vitaly Koulakov, Anton Gackovka, Alexandr Ryabokon, Mikhail Mironov
 * Created: October 05, 2017
 *
 */

import Duck from 'services/Duck';
import { apiPaths } from 'modules/AnalysisExecution/const';

const code = new Duck({
  name: 'AE_ANALYSIS_CODE',
  urlBuilder: (urlParams) => {
    switch (urlParams.action) {
      case 'CREATE':
        return apiPaths.uploadAnalysisCode(urlParams);
      default:
        return apiPaths.analysisCode(urlParams);
    }
  },
});

export default {
  actions: {
    update: (urlParams, data) => code.actions.update({
      ...urlParams,
      action: 'UPDATE',
    }, data),
    delete: (urlParams, data) => code.actions.delete({
      ...urlParams,
      action: 'REMOVE',
    }, data),
    create: (urlParams, data) => code.actions.create({
      ...urlParams,
      action: 'CREATE',
    }, data),
  },
};
