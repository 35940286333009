import { createSelector } from 'reselect';
import { get } from 'services/Utils';

export interface IDataSource {
  value: number;
  label: string;
  healthStatus: {
    title: string;
    value: string;
  }
};

const getRawStudySourceList = state => get(state, 'analysisExecution.studyDataSourceList.queryResult.result') || [];

const getDataSources = createSelector(
  [getRawStudySourceList],
  rawList => rawList.map(dataSource => ({
    value: dataSource.id,
    label: dataSource.name,
    healthStatus: {
      title: dataSource.healthStatusTitle,
      value: dataSource.healthStatus,
    },
  }))
);

export default {
  getDataSources,
};
