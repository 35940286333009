import Duck from 'services/Duck';
import { apiPaths } from 'modules/StudyManager/const';
import * as Uri from 'urijs';
import api from 'services/Api';
import ReducerFactory from 'services/ReducerFactory';
import { Utils } from 'services/Utils';

const actionCoreName = 'SM_STUDY_RECENT_ACTIVITY';
const activitiesSubscrUpdateAction = `${actionCoreName}_SUBSCRIPTION`;

const studiesDuck = new Duck({
  name: actionCoreName,
  urlBuilder: ({ studyId, pageSize }) => {
    const url = new Uri(apiPaths.recentActivity(studyId));
    url.setSearch({
      pageSize,
    });
    return url.href();
  },
});


function updateActivitiesSubscr(data) {
  return {
    type: activitiesSubscrUpdateAction,
    payload: data,
  };
}

const activitiesSubscriptionReducer = new ReducerFactory()
  .setActionHandler(
    activitiesSubscrUpdateAction,
    (state, action) => ({
      ...state,
      data: action.payload,
    })
  )
  .build();

const actions = {
  ...studiesDuck.actions,
  subscribeToActivities: (studyId) => {
    return (dispatch) => {
      api
        .subscribe(apiPaths.activitiesSubscription(), () => {
          dispatch(studiesDuck.actions.find({ studyId, pageSize: 10 }));
        })
        .then(({ id }) => {
          dispatch(updateActivitiesSubscr({ id }));
        });
    };
  },
  unsubscribeOfActivities: ({ subscriptionId }) => {
    return () => {
      api.unsubscribe(subscriptionId);
    };
  },
};

export default {
  actions,
  reducer: Utils.extendReducer(
    studiesDuck.reducer,
    {
      subscription: activitiesSubscriptionReducer,
    }
  ),
};
