import Duck from 'services/Duck';
import api from 'services/Api';
import { apiPaths } from 'modules/Portal/const';
import { combineReducers } from 'redux';

const subscriptionName = 'PORTAL_NOTIFICATION_SUBSCRIPTION';

const notification = new Duck({
  name: 'PORTAL_NOTIFICATION',
  urlBuilder: apiPaths.notifications,
});

const subscription = new Duck({
  name: subscriptionName,
  urlBuilder: () => '',
});


function subscritionChanged(active) {
  return {
    type: `${subscriptionName}_FIND_FULFILLED`,
    payload: {
      active,
    },
  };
}

export default {
  actions: {
    ...notification.actions,
    subscribeToNotifications: () => {
      return (dispatch) => {
        dispatch(subscritionChanged(true));
        api.subscribe(apiPaths.notificationSubscription(), () => {
          dispatch(notification.actions.query());
        });
      };
    },
    unsubscribeOfNotifications: () => {
      return (dispatch) => {
        dispatch(subscritionChanged(false));
        api.unsubscribe(apiPaths.notificationSubscription());
      };
    },
    markAllAsRead: (notificationIds) => notification.actions.create(null, { notificationIds }),
  },
  reducer: combineReducers({
    list: notification.reducer,
    subscription: subscription.reducer,
  }),
};
