import * as React from 'react';
import {
  Link
} from 'arachne-ui-components';
import {
  paths,
} from 'const/activity';

function Analysis(props) {
  const { id, title } = props;

  return (<Link to={paths.analyses(id)}>{title}</Link>);
}

export function AnalysisText(props) {
  const { id, title } = props;

  return title;
}

export default Analysis;  
