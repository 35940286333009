import * as React from 'react';
import BEMHelper from 'services/BemHelper';
import {
  Button,
  Checkbox,
  Modal,
  TabbedPane,
} from 'arachne-ui-components';
import { Field } from 'redux-form';
import Schedule from './Schedule';
import DataSources from 'modules/AnalysisExecution/components/ViewEditAnalysis/DataSources';

import './style.scss';

function ToggleRepeat({ input }) {
  return (
    <Checkbox
      isChecked={input.value}
      onChange={input.onChange}
      label='Enable'
    />
  );
}

function Scheduler(props) {
  const classes = BEMHelper('analysis-scheduler');
  const {
    closeModal,
    dataSources,
    doSubmit,
    handleSubmit,
    jobId,
    modal,
    onEndConditionChange,
    schedulerEnabled,
    shouldPickDays,
    checkForChanges,
    selectedDataSourcesLength,
    timeZone,
    isOnce,
  } = props;

  const title = (
    <span {...classes('toggle-repeat')}>
      <Field component={ToggleRepeat} name='repeat' />
    </span>
  );

  const dataSourcesLabel = 'Data sources selected';
  const canSave = (schedulerEnabled && !!selectedDataSourcesLength || !schedulerEnabled && jobId) && props.dirty;
  const sections = [
    {
      label: 'Schedule',
      content: <Schedule
        disabled={!schedulerEnabled}
        onEndConditionChange={onEndConditionChange}
        shouldPickDays={shouldPickDays}
        timeZone={timeZone}
        isOnce={isOnce}
      />,
    },
    {
      label: dataSourcesLabel,
      customLabel: `${dataSourcesLabel} (${selectedDataSourcesLength})`,
      content: <DataSources
        disabled={!schedulerEnabled}
        dataSources={dataSources}
      />,
    }
  ];
  
  return (
    <div {...classes()}>
      <Modal
        mods={['no-padding']}
        modal={modal} title={title}
        onBeforeClose={() => checkForChanges(props.dirty)}
      >
        <form onSubmit={handleSubmit(doSubmit)}>
          <TabbedPane sections={sections} />
          {props.error &&
            <div {...classes('error')}>
              <span {...classes('error-message')}>{props.error}</span>
            </div>
          } 
          <div {...classes('actions')}>
            <span data-tootik={ (!canSave && !jobId) || (jobId && selectedDataSourcesLength == 0) ? "Choose at least one data source" : null }>
              <Button
                  mods={['success', 'rounded']}
                  {...classes({ element: 'button', modifiers: { disabled: !canSave } })}
                  type='submit'
                  disabled={!canSave}
              >
                {jobId ? 'Update' : 'Save'}
              </Button>
            </span>
            <Button
              mods={['cancel', 'rounded']}
              {...classes('button')}
              onClick={closeModal}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default Scheduler;
