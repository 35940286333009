/*
  *
  * Copyright 2018 Odysseus Data Services, inc.
  * Licensed under the Apache License, Version 2.0 (the "License");
  * you may not use this file except in compliance with the License.
  * You may obtain a copy of the License at
  *
  *     http://www.apache.org/licenses/LICENSE-2.0
  *
  * Unless required by applicable law or agreed to in writing, software
  * distributed under the License is distributed on an "AS IS" BASIS,
  * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  * See the License for the specific language governing permissions and
  * limitations under the License.
  *
  * Company: Odysseus Data Services, Inc.
  * Product Owner/Architecture: Gregory Klebanov
  * Authors: Pavel Grafkin, Alexander Saltykov, Vitaly Koulakov, Anton Gackovka, Alexandr Ryabokon, Mikhail Mironov
  * Created: Tuesday, May 22, 2018 7:18 PM
  *
  */

import React from 'react';
import { Modal } from 'arachne-ui-components';
import BEMHelper from 'services/BemHelper';

import './style.scss';

function ModalError(props) {
  const classes = new BEMHelper('modal-error');

  return (
    <Modal modal={props.modal} title={props.title || 'Error'}>
      <ul {...classes()}>
        {Array.isArray(props.errors) && props.errors.map((error, idx) =>
          <li key={idx} {...classes('error')}>{error}</li>
        )}
      </ul>
    </Modal>
  );
}

export default ModalError;
  
