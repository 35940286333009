import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { get } from 'services/Utils';
import { push as goToPage } from 'react-router-redux';
import actions from 'actions';
import { form, paths } from 'modules/DataCatalog/const';
import ReportAccessParams from './presenter';
import selectors from './selectors';

function mapStateToProps(state) {
  return {
    accessOptions: selectors.getAccessOptions(state),
    datasourceId: selectors.getDatasourceId(state),
    initialValues: selectors.getInitialValues(state),
    reportsPermissions: selectors.getReportsPermissions(state),
  };
}

const mapDispatchToProps = {
  update: actions.dataCatalog.reportsPermissions.update,
  goToList: () => goToPage(paths.dataSources()),
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    doSubmit(reportsPermissions) {
      const promises = Object.keys(reportsPermissions).map((id) => dispatchProps.update({
          datasourceId: stateProps.datasourceId,
          id,
        },
        { grantType: reportsPermissions[id] }
      ));
      const submitPromise = Promise.all(promises)
        .then(() => dispatchProps.goToList())
        .catch(() => {});

      return submitPromise;
    },
  };
}

const ReduxReportAccessParams = reduxForm({
  form: form.reportAccessParams,
  enableReinitialize: true,
})(ReportAccessParams);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ReduxReportAccessParams);
