import { Component, PropTypes } from 'react';
import { Utils, numberFormatter, get } from 'services/Utils';
import ducks from 'modules/Portal/ducks';
import ConnectedComponentBuilder from 'interfaces/ConnectedComponentBuilder';
import presenter from './presenter';
import { Notifier } from 'community/services/Notifier';
import * as pluralize from 'pluralize';
import { getTextActivity } from 'components/ActivityItem/presenter';
import SelectorsBuilder from './selectors';

const selectors = (new SelectorsBuilder()).build();

interface NotificationState {
  notifications: Array<any>,
  unreadCount: number,
  isLoading: boolean,
  isSubscribed: boolean,
};

interface NotificationDispatch {
  loadNotifications: Function,
  subscribeToNotifications: Function,
  unsubscribeOfNotifications: Function,
  markAsRead: Function,
};

interface NotificationProps extends NotificationState, NotificationDispatch {
  markAllAsRead: Function,
};

interface IMessage {
  title: any;
  body: string;
  icon: any;
}

class NotificationList extends Component<NotificationProps, {}> {
  async componentWillMount() {
    await this.props.loadNotifications();
    this.props.subscribeToNotifications();
  }

  componentWillUnmount() {
    this.props.unsubscribeOfNotifications();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isSubscribed && this.props.unreadCount < nextProps.unreadCount) {
      const message: IMessage = nextProps.unreadCount > 1
        ? {
          title: '',
          body: `You have ${numberFormatter.format(nextProps.unreadCount, 'whole')} new ${pluralize('notification', nextProps.unreadCount)}`,
          icon: null,
        }
        : getTextActivity(nextProps.notifications[0]);
      Notifier.alert({
        title: message.title,
        message: message.body,
        icon: message.icon,
        useFallback: false,
      });
    }
  }

  render() {
    return presenter(this.props);
  }
}

export default class NotificationListBuilder implements ConnectedComponentBuilder {
  getComponent() {
    return NotificationList;
  }

  mapStateToProps(state: Object): NotificationState {
    const notifications = selectors.getNotifications(state);
    const unreadCount = notifications.filter(
      item => item.shown === false).length;
    const isLoading = get(state, 'portal.notifications.isLoading', false);
    const isSubscribed = get(state, 'portal.notifications.subscription.data.active', false);

    return {
      notifications,
      unreadCount,
      isLoading,
      isSubscribed,
    };
  }

  getMapDispatchToProps(): NotificationDispatch {
    return {
      loadNotifications: ducks.actions.notifications.query,
      markAsRead: ducks.actions.notifications.markAllAsRead,
      subscribeToNotifications: ducks.actions.notifications.subscribeToNotifications,
      unsubscribeOfNotifications: ducks.actions.notifications.unsubscribeOfNotifications,
    };
  }

  mergeProps(stateProps, dispatchProps, ownProps): NotificationProps {
    return {
      ...stateProps,
      ...ownProps,
      ...dispatchProps,
      markAllAsRead: () => {
        const newlyReadIds = stateProps.notifications
          .filter(notification => notification.shown === false)
          .map(notification => notification.id);

        if (newlyReadIds.length > 0) {
          dispatchProps
            .markAsRead(newlyReadIds)
            .then(dispatchProps.loadNotifications);
        }
      },
    };
  }

  build() {
    return Utils.buildConnectedComponent({
      Component: this.getComponent(),
      mapStateToProps: this.mapStateToProps,
      mapDispatchToProps: this.getMapDispatchToProps(),
      mergeProps: this.mergeProps,
    });
  }
}

